interface IProps {
  name: string;
  className: string;

  url: string;
  show_img: string;
  name_tag: string;
}

const domain = 'https://www.giftnet.jp/';

const MeetingComponent = (props: IProps): string => {
  return `
            <a class='{props.className} d-block  card-link' data-value='${props.name_tag}' href="${props.url}" target="_blank" rel="noopener noreferrer">
                  <img src="${domain}${props.show_img}" />
             </a>
  `;
};
export default MeetingComponent;
