interface ChildProps {
  id: number;
  name: string;
}

interface IProps {
  className: string;
  index: number;
  main_name: string;
  detail: ChildProps[];
}

const AbcComponent = (props: IProps): string => {
  let child_valueList: string = '';

  const categoryChildContent = props.detail.map((item: ChildProps) => {
    if (item.name === '') {
      child_valueList += `
      <li value='${item.name}' class='transparent'>${item.name}</li>
      `;
    } else {
      child_valueList += `
      <li value='${item.name}' class='abc-list-value-li'>${item.name}</li>
   `;
    }
  });
  return `
    <div class='d-flex justify-content-start align-items-center'>
          <h4 class='sub-text abc-title'>${props.main_name}</h4>
          <ul>
          ${child_valueList}
          </ul>
    </div>
    `;
};
export default AbcComponent;
