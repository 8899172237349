interface IProps {
  value: number | string;
  className: string;
  category_code: string;
  category_name: string;
  img_url?: string;
}

const CategoryComponent = (props: IProps): string => {
  return `
          <a class=' ${props.className} card pointer col-12 col-md-6 col-lg-3 ml-0' data-value='${props.value}' href='./result/?category=${props.category_code}' >
            <div class='card-body label-body card-link p-0 d-flex align-items-center'>
                <img class='label-img d-block mx-3' src='${props.img_url}' alt='${props.category_name}'>
                <span class='label-text d-block pt-2'>${props.category_name}</span>
                <span class= "ml-auto mr-3 text-muted">
                  <i class="fa fa-angle-right"></i>             
                </span>
            </div>
         </a>
  `;
};
export default CategoryComponent;
