import { Abc, Def } from './model/index';

// 検索条件
export const conditions: string[] = [
  'page', // ページ
  'show', // ショー
  'floor', // フロア
  'fair', // フェア
  'freeword', // フリーワード
  'category', // 商品カテゴリ
  'hashtag', // ハッシュタグ
  'abc', // アイウエオ・ABC
  'prefecture', // 都道府県
  'content', // スペシャルコンテンツ
  'product_name', // 商品名
  'registered_name', // 出展社名
  'order', // 並べ替え
  'lang', // 言語
];

// 言語設定
export const langList: string[] = [
  'ja',
  'en',
  'cn'
];

// アイウエオ・ABC
export const nameIndexs: string[] = [
  'あ行',
  'か行',
  'さ行',
  'た行',
  'な行',
  'は行',
  'ま行',
  'や行',
  'ら行',
  'わ行',
  'ABC',
  'DEF',
  'GHI',
  'JKL',
  'MNO',
  'PQR',
  'STU',
  'VWX',
  'YZ',
];

export const nameIndexs_en: string[] = [
  'ABCDE',
  'FGHIJ',
  'KLMNO',
  'PQRST',
  'UVWXYZ',
];

export const abcNameWebIndex: Abc[] = [
  {
    main_name: 'あ行',
    detail: [
      {
        id: 1,
        name: 'あ',
      },
      {
        id: 2,
        name: 'い',
      },
      {
        id: 3,
        name: 'う',
      },
      {
        id: 4,
        name: 'え',
      },
      {
        id: 5,
        name: 'お',
      },
    ],
  },
  {
    main_name: 'か行',
    detail: [
      {
        id: 6,
        name: 'か',
      },
      {
        id: 7,
        name: 'き',
      },
      {
        id: 8,
        name: 'く',
      },
      {
        id: 9,
        name: 'け',
      },
      {
        id: 10,
        name: 'こ',
      },
    ],
  },
  {
    main_name: 'さ行',
    detail: [
      {
        id: 11,
        name: 'さ',
      },
      {
        id: 12,
        name: 'し',
      },
      {
        id: 13,
        name: 'す',
      },
      {
        id: 14,
        name: 'せ',
      },
      {
        id: 15,
        name: 'そ',
      },
    ],
  },
  {
    main_name: 'た行',
    detail: [
      {
        id: 16,
        name: 'た',
      },
      {
        id: 17,
        name: 'ち',
      },
      {
        id: 18,
        name: 'つ',
      },
      {
        id: 19,
        name: 'て',
      },
      {
        id: 20,
        name: 'と',
      },
    ],
  },
  {
    main_name: 'な行',
    detail: [
      {
        id: 21,
        name: 'な',
      },
      {
        id: 22,
        name: 'に',
      },
      {
        id: 23,
        name: 'ぬ',
      },
      {
        id: 24,
        name: 'ね',
      },
      {
        id: 25,
        name: 'の',
      },
    ],
  },
  {
    main_name: 'は行',
    detail: [
      {
        id: 26,
        name: 'は',
      },
      {
        id: 27,
        name: 'ひ',
      },
      {
        id: 28,
        name: 'ふ',
      },
      {
        id: 29,
        name: 'へ',
      },
      {
        id: 30,
        name: 'ほ',
      },
    ],
  },
];

export const defNameWebIndex: Def[] = [
  {
    main_name: 'ま行',
    detail: [
      {
        id: 31,
        name: 'ま',
      },
      {
        id: 32,
        name: 'み',
      },
      {
        id: 33,
        name: 'む',
      },
      {
        id: 34,
        name: 'め',
      },
      {
        id: 35,
        name: 'も',
      },
    ],
  },
  {
    main_name: 'や行',
    detail: [
      {
        id: 36,
        name: 'や',
      },
      {
        id: 37,
        name: '',
      },
      {
        id: 38,
        name: 'ゆ',
      },
      {
        id: 39,
        name: '',
      },
      {
        id: 40,
        name: 'よ',
      },
    ],
  },
  {
    main_name: 'ら行',
    detail: [
      {
        id: 41,
        name: 'ら',
      },
      {
        id: 42,
        name: 'り',
      },
      {
        id: 43,
        name: 'る',
      },
      {
        id: 44,
        name: 'れ',
      },
      {
        id: 45,
        name: 'ろ',
      },
    ],
  },
  {
    main_name: 'A~O',
    detail: [
      {
        id: 46,
        name: 'ABC',
      },
      {
        id: 47,
        name: 'DEF',
      },
      {
        id: 48,
        name: 'GHI',
      },
      {
        id: 49,
        name: 'JKL',
      },
      {
        id: 50,
        name: 'MNO',
      },
    ],
  },
  {
    main_name: 'P~Z',
    detail: [
      {
        id: 51,
        name: 'PQR',
      },
      {
        id: 52,
        name: 'STU',
      },
      {
        id: 53,
        name: 'VWX',
      },
      {
        id: 54,
        name: 'YZ',
      },
    ],
  },
];

export const abcNameWebIndex_en: Abc[] = [
  {
    main_name: 'A~E',
    detail: [
      {
        id: 1,
        name: 'A',
      },
      {
        id: 2,
        name: 'B',
      },
      {
        id: 3,
        name: 'C',
      },
      {
        id: 4,
        name: 'D',
      },
      {
        id: 5,
        name: 'E',
      },
    ],
  },
  {
    main_name: 'F~J',
    detail: [
      {
        id: 6,
        name: 'F',
      },
      {
        id: 7,
        name: 'G',
      },
      {
        id: 8,
        name: 'H',
      },
      {
        id: 9,
        name: 'I',
      },
      {
        id: 10,
        name: 'J',
      },
    ],
  },
  {
    main_name: 'K~O',
    detail: [
      {
        id: 11,
        name: 'K',
      },
      {
        id: 12,
        name: 'L',
      },
      {
        id: 13,
        name: 'M',
      },
      {
        id: 14,
        name: 'N',
      },
      {
        id: 15,
        name: 'O',
      },
    ],
  },
  {
    main_name: 'P~T',
    detail: [
      {
        id: 16,
        name: 'P',
      },
      {
        id: 17,
        name: 'Q',
      },
      {
        id: 18,
        name: 'R',
      },
      {
        id: 19,
        name: 'S',
      },
      {
        id: 20,
        name: 'T',
      },
    ],
  },
  {
    main_name: 'V~Z',
    detail: [
      {
        id: 21,
        name: 'V',
      },
      {
        id: 22,
        name: 'W',
      },
      {
        id: 23,
        name: 'X',
      },
      {
        id: 24,
        name: 'Y',
      },
      {
        id: 25,
        name: 'Z',
      },
    ],
  },
];
