import { removeCookie } from './cookie';
import { logoutJqXHR } from '../api';
import { url } from './menuUrl';
import { Lang } from './translate';

// 言語
const lang = (localStorage.getItem('lang') ?? 'ja') as keyof Lang;

// 遷移先URL
const loginUri = url['login'][lang];
const topUri = '/';

export const login = (e?: JQuery.ClickEvent) => {
  e?.preventDefault();
  location.href = loginUri;
};

export const logout = () => {
  logoutJqXHR().always(() => {
    removeCookie('loginToken');
    location.href = topUri;
  });
};
