interface IProps {
  value: number | string;
  name: string;
  className: string;
}

const HashtagLabelComponent = (props: IProps): string => {
  return `
        <div class='${props.className} card pointer col-12 col-md-3 mb-2' data-value='${props.value}'>
            <div class='card-body card-link label-body'>
                <span class='label-text d-block'>${props.name}</span>
            </div>
        </div>
  `;
};
export default HashtagLabelComponent;
