interface IProps {
  area_name: string;
  className: string;
  provinces: ChildProps[];
}

interface ChildProps {
  id: number;
  name: string;
}

const AreaPrefectureComponent = (props: IProps): string => {
  let prefectureNamelist: string = '';
  props.provinces.map((item: ChildProps) => {
    prefectureNamelist += `
       <a class='card-link' href='./result/?prefecture=${item.id}'> <li>${item.name}</li></a>
    `;
  });
  return `
  <div class='prefecture-row'>
        <h4 class='sub-text prefecture-title'>${props.area_name}</h4>
        <ul>
          ${prefectureNamelist}
        </ul>
   </div>
  `;
};
export default AreaPrefectureComponent;
