// 参考サイト
// https://illbenet.jp/view/js-get_param?pathinfo=view/95

// URLパラメータから値を取得
export const getParam = (key: string) => {
  const url = window.location.href;
  const name = key.replace(/[[\]]/g, '\\$&');
  const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
  const results = regex.exec(url);
  if (!results || !results[2]) return null;
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

// URLパラメータから値を取得
export const getParams = (keys: string[]) => {
  const results: {
    [index: string]: ReturnType<typeof getParam>;
  } = {};
  keys.forEach((item) => {
    results[item] = getParam(item);
  });
  return results;
};

// URLパラメータ生成
export const createParams = (params: { [key: string]: string }) => {
  let result: string = '?';
  for (let key in params) {
    result += `${key}=${params[key]}&`;
  }
  result = result.slice(0, -1);
  return result;
};
