import $ from 'jquery';
import { Lang } from './translate';

interface Url {
  login: Lang;
  guide: Lang;
  registration: Lang;
  mypage: Lang;
  contact: Lang;
  sampleGuidance: Lang;
  privacy: Lang;
  company: Lang;
}

export const url: Url = {
  login: {
    ja: '/registration/login.html?redirect_uri=https://www.giftnet.jp',
    en: '/registration/login.html?redirect_uri=https://www.giftnet.jp',
    cn: '/registration/login.html?redirect_uri=https://www.giftnet.jp',
  },
  guide: {
    ja: 'https://www.giftshow.co.jp/pdf/guide.pdf',
    en: 'https://www.giftshow.co.jp/pdf/guide_en.pdf',
    cn: 'https://www.giftshow.co.jp/pdf/guide_en.pdf',
  },
  registration: {
    ja: '/registration/index.html?lang=ja',
    en: '/registration/index.html?lang=en',
    cn: '/registration/index.html?lang=cn',
  },
  mypage: {
    ja: '/registration/mypage/index.html?lang=ja',
    en: '/registration/mypage/index.html?lang=en',
    cn: '/registration/mypage/index.html?lang=cn',
  },
  contact: {
    ja: 'https://www.giftshow.co.jp/QR/faq/index.htm',
    en: 'https://www.giftshow.co.jp/english/qr/faq.htm',
    cn: 'https://www.giftshow.co.jp/english/qr/faq.htm',
  },
  sampleGuidance: {
    ja: 'https://www.giftshow.co.jp',
    en: 'https://www.giftshow.co.jp/english/',
    cn: 'https://www.giftshow.co.jp/english/',
  },
  privacy: {
    ja: 'https://www.giftshow.co.jp/aboutus/privacy.htm',
    en: 'https://www.giftshow.co.jp/english/aboutus/privacy.htm',
    cn: 'https://www.giftshow.co.jp/english/aboutus/privacy.htm',
  },
  company: {
    ja: 'https://www.giftshow.co.jp/aboutus/aboutus.htm',
    en: 'https://www.giftshow.co.jp/english/aboutus/aboutus.htm',
    cn: 'https://www.giftshow.co.jp/english/aboutus/aboutus.htm',
  },
};

$(() => {
  const lang = (localStorage.getItem('lang') ?? 'ja') as keyof Lang;

  $('.menu-url').each((_, e) => {
    const trn_key = $(e).data('trn-key') as keyof Url;
    if (trn_key in url) {
      if (url[trn_key][lang]) $(e).attr('href', url[trn_key][lang]);
    }
  });
});
