import $ from 'jquery';
import 'slick-carousel';
let slicktype = '';
const breakPoint = 630;

const slickPcSpecial = () => {
  $('.special-slick').slick({
    autoplay: true,
    swipe: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 2,
    adaptiveHeight: true,
    variableWidth: true,
    arrows: false,
    prevArrow: '',
    nextArrow: `<svg class='next-arrow' xmlns="http://www.w3.org/2000/svg" width="40" height="60" viewBox="0 0 40 60">
  <g id="矢印アイコン_右4" data-name="矢印アイコン　右4" transform="translate(-92.084)">
    <g id="パス_305" data-name="パス 305" fill="#333">
      <path d="M 100.8484115600586 57.9202766418457 L 94.24949645996094 51.5832633972168 L 115.5948333740234 31.08135032653809 L 116.7211761474609 29.99950981140137 L 115.5948104858398 28.91769027709961 L 94.24951934814453 8.416712760925293 L 100.8484344482422 2.079709768295288 L 129.9185485839844 29.99954795837402 L 100.8484115600586 57.9202766418457 Z" stroke="none"/>
      <path d="M 100.8483657836914 4.159423828125 L 96.4150390625 8.416793823242188 L 118.886589050293 29.99948883056641 L 96.41499328613281 51.58316040039062 L 100.8483352661133 55.84054565429688 L 127.7531127929688 29.99956703186035 L 100.8483657836914 4.159423828125 M 100.848503112793 0 L 132.0839996337891 29.99953269958496 L 100.848503112793 60 L 92.08399963378906 51.58335494995117 L 114.5557556152344 29.99953269958496 L 92.08399963378906 8.416645050048828 L 100.848503112793 0 Z" stroke="none" fill="#fff"/>
    </g>
  </g>
</svg>
`,
  });
};
const slickSmtSpecial = () => {
  $('.special-slick').slick({
    autoplay: true,
    swipe: true,
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    adaptiveHeight: true,
    variableWidth: true,
    arrows: false,
    prevArrow: '',
    nextArrow: `<svg class='next-arrow' xmlns="http://www.w3.org/2000/svg" width="40" height="60" viewBox="0 0 40 60">
  <g id="矢印アイコン_右4" data-name="矢印アイコン　右4" transform="translate(-92.084)">
    <g id="パス_305" data-name="パス 305" fill="#333">
      <path d="M 100.8484115600586 57.9202766418457 L 94.24949645996094 51.5832633972168 L 115.5948333740234 31.08135032653809 L 116.7211761474609 29.99950981140137 L 115.5948104858398 28.91769027709961 L 94.24951934814453 8.416712760925293 L 100.8484344482422 2.079709768295288 L 129.9185485839844 29.99954795837402 L 100.8484115600586 57.9202766418457 Z" stroke="none"/>
      <path d="M 100.8483657836914 4.159423828125 L 96.4150390625 8.416793823242188 L 118.886589050293 29.99948883056641 L 96.41499328613281 51.58316040039062 L 100.8483352661133 55.84054565429688 L 127.7531127929688 29.99956703186035 L 100.8483657836914 4.159423828125 M 100.848503112793 0 L 132.0839996337891 29.99953269958496 L 100.848503112793 60 L 92.08399963378906 51.58335494995117 L 114.5557556152344 29.99953269958496 L 92.08399963378906 8.416645050048828 L 100.848503112793 0 Z" stroke="none" fill="#fff"/>
    </g>
  </g>
</svg>
`,
  });
};
export const checkSlickSpecial = () => {
  $(() => {
    if (window.innerWidth < breakPoint) {
      slickSmtSpecial();
      slicktype = 'smt';
    } else {
      slickPcSpecial();
      slicktype = 'pc';
    }
    $(window).on('resize', () => {
      if (window.innerWidth < breakPoint && slicktype == 'pc') {
        $('.special-slick').slick('unslick');
        slickSmtSpecial();
        slicktype = 'smt';
      } else if (window.innerWidth >= breakPoint && slicktype == 'smt') {
        $('special-slick').slick('unslick');
        slickPcSpecial();
        slicktype = 'pc';
      }
    });
  });
};

// Gitshow
const slickPcGiftshow = () => {
  $('.gift-slick').slick({
    autoplay: true,
    swipe: true,
    infinite: true,
    adaptiveHeight: true,
    variableWidth: true,
    slidesToShow: 5,
    slidesToScroll: 2,
    arrows: false,
    prevArrow: '',
    nextArrow: `<svg class='next-arrow' xmlns="http://www.w3.org/2000/svg" width="40" height="60" viewBox="0 0 40 60">
  <g id="矢印アイコン_右4" data-name="矢印アイコン　右4" transform="translate(-92.084)">
    <g id="パス_305" data-name="パス 305" fill="#333">
      <path d="M 100.8484115600586 57.9202766418457 L 94.24949645996094 51.5832633972168 L 115.5948333740234 31.08135032653809 L 116.7211761474609 29.99950981140137 L 115.5948104858398 28.91769027709961 L 94.24951934814453 8.416712760925293 L 100.8484344482422 2.079709768295288 L 129.9185485839844 29.99954795837402 L 100.8484115600586 57.9202766418457 Z" stroke="none"/>
      <path d="M 100.8483657836914 4.159423828125 L 96.4150390625 8.416793823242188 L 118.886589050293 29.99948883056641 L 96.41499328613281 51.58316040039062 L 100.8483352661133 55.84054565429688 L 127.7531127929688 29.99956703186035 L 100.8483657836914 4.159423828125 M 100.848503112793 0 L 132.0839996337891 29.99953269958496 L 100.848503112793 60 L 92.08399963378906 51.58335494995117 L 114.5557556152344 29.99953269958496 L 92.08399963378906 8.416645050048828 L 100.848503112793 0 Z" stroke="none" fill="#fff"/>
    </g>
  </g>
</svg>
`,
  });
};
const slickSmtGiftshow = () => {
  $('.gift-slick').slick({
    autoplay: true,
    swipe: true,
    infinite: true,
    adaptiveHeight: true,
    variableWidth: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    prevArrow: '',
    nextArrow: `<svg class='next-arrow' xmlns="http://www.w3.org/2000/svg" width="40" height="60" viewBox="0 0 40 60">
  <g id="矢印アイコン_右4" data-name="矢印アイコン　右4" transform="translate(-92.084)">
    <g id="パス_305" data-name="パス 305" fill="#333">
      <path d="M 100.8484115600586 57.9202766418457 L 94.24949645996094 51.5832633972168 L 115.5948333740234 31.08135032653809 L 116.7211761474609 29.99950981140137 L 115.5948104858398 28.91769027709961 L 94.24951934814453 8.416712760925293 L 100.8484344482422 2.079709768295288 L 129.9185485839844 29.99954795837402 L 100.8484115600586 57.9202766418457 Z" stroke="none"/>
      <path d="M 100.8483657836914 4.159423828125 L 96.4150390625 8.416793823242188 L 118.886589050293 29.99948883056641 L 96.41499328613281 51.58316040039062 L 100.8483352661133 55.84054565429688 L 127.7531127929688 29.99956703186035 L 100.8483657836914 4.159423828125 M 100.848503112793 0 L 132.0839996337891 29.99953269958496 L 100.848503112793 60 L 92.08399963378906 51.58335494995117 L 114.5557556152344 29.99953269958496 L 92.08399963378906 8.416645050048828 L 100.848503112793 0 Z" stroke="none" fill="#fff"/>
    </g>
  </g>
</svg>
`,
  });
};

export const checkBreakPointGiftshow = () => {
  $(() => {
    if (window.innerWidth < breakPoint) {
      slickSmtGiftshow();
      slicktype = 'smt';
    } else {
      slickPcGiftshow();
      slicktype = 'pc';
    }
    $(window).on('resize', () => {
      if (window.innerWidth < breakPoint && slicktype == 'pc') {
        $('.gift-slick').slick('unslick');
        slickSmtGiftshow();
        slicktype = 'smt';
      } else if (window.innerWidth >= breakPoint && slicktype == 'smt') {
        $('gift-slick').slick('unslick');
        slickPcGiftshow();
        slicktype = 'pc';
      }
    });
  });
};

// 産地商談会
const slickPcMeeting = () => {
  $('.meeting-slick').slick({
    infinite: true,
    swipe: true,
    autoplay: true,
    // accessibility: true,
    // swipe: true,
    adaptiveHeight: true,
    variableWidth: true,
    slidesToShow: 5,
    slidesToScroll: 2,
    arrows: false,
    prevArrow: '',
    nextArrow: `<svg class='next-arrow' xmlns="http://www.w3.org/2000/svg" width="40" height="60" viewBox="0 0 40 60">
  <g id="矢印アイコン_右4" data-name="矢印アイコン　右4" transform="translate(-92.084)">
    <g id="パス_305" data-name="パス 305" fill="#333">
      <path d="M 100.8484115600586 57.9202766418457 L 94.24949645996094 51.5832633972168 L 115.5948333740234 31.08135032653809 L 116.7211761474609 29.99950981140137 L 115.5948104858398 28.91769027709961 L 94.24951934814453 8.416712760925293 L 100.8484344482422 2.079709768295288 L 129.9185485839844 29.99954795837402 L 100.8484115600586 57.9202766418457 Z" stroke="none"/>
      <path d="M 100.8483657836914 4.159423828125 L 96.4150390625 8.416793823242188 L 118.886589050293 29.99948883056641 L 96.41499328613281 51.58316040039062 L 100.8483352661133 55.84054565429688 L 127.7531127929688 29.99956703186035 L 100.8483657836914 4.159423828125 M 100.848503112793 0 L 132.0839996337891 29.99953269958496 L 100.848503112793 60 L 92.08399963378906 51.58335494995117 L 114.5557556152344 29.99953269958496 L 92.08399963378906 8.416645050048828 L 100.848503112793 0 Z" stroke="none" fill="#fff"/>
    </g>
  </g>
</svg>
`,
  });
};
const slickSmtMeeting = () => {
  $('.meeting-slick').slick({
    infinite: true,
    swipe: true,
    autoplay: true,
    // accessibility: true,
    // swipe: true,
    adaptiveHeight: true,
    variableWidth: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    prevArrow: '',
    nextArrow: `<svg class='next-arrow' xmlns="http://www.w3.org/2000/svg" width="40" height="60" viewBox="0 0 40 60">
  <g id="矢印アイコン_右4" data-name="矢印アイコン　右4" transform="translate(-92.084)">
    <g id="パス_305" data-name="パス 305" fill="#333">
      <path d="M 100.8484115600586 57.9202766418457 L 94.24949645996094 51.5832633972168 L 115.5948333740234 31.08135032653809 L 116.7211761474609 29.99950981140137 L 115.5948104858398 28.91769027709961 L 94.24951934814453 8.416712760925293 L 100.8484344482422 2.079709768295288 L 129.9185485839844 29.99954795837402 L 100.8484115600586 57.9202766418457 Z" stroke="none"/>
      <path d="M 100.8483657836914 4.159423828125 L 96.4150390625 8.416793823242188 L 118.886589050293 29.99948883056641 L 96.41499328613281 51.58316040039062 L 100.8483352661133 55.84054565429688 L 127.7531127929688 29.99956703186035 L 100.8483657836914 4.159423828125 M 100.848503112793 0 L 132.0839996337891 29.99953269958496 L 100.848503112793 60 L 92.08399963378906 51.58335494995117 L 114.5557556152344 29.99953269958496 L 92.08399963378906 8.416645050048828 L 100.848503112793 0 Z" stroke="none" fill="#fff"/>
    </g>
  </g>
</svg>
`,
  });
};
export const checkSlickMeeting = () => {
  $(() => {
    if (window.innerWidth < breakPoint) {
      slickSmtMeeting();
      slicktype = 'smt';
    } else {
      slickPcMeeting();
      slicktype = 'pc';
    }
    $(window).on('resize', () => {
      if (window.innerWidth < breakPoint && slicktype == 'pc') {
        $('.meeting-slick').slick('unslick');
        slickSmtMeeting();
        slicktype = 'smt';
      } else if (window.innerWidth >= breakPoint && slicktype == 'smt') {
        $('meeting-slick').slick('unslick');
        slickPcMeeting();
        slicktype = 'pc';
      }
    });
  });
};
