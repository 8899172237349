import Swal from 'sweetalert2';
import { Lang, Tran, t } from './translate';

interface IAlert {
  icon: 'warning' | 'error' | 'success' | 'info' | 'question';
  titleKey?: keyof Tran;
  trnKey: keyof Tran;
  confirmButtonKey?: keyof Tran;
  onClick?: () => void;
}

const Alert = (props: IAlert) => {
  let lsLang = localStorage.getItem('lang') as keyof Lang | null;
  if (!lsLang) lsLang = 'ja';
  let title;
  if (props.titleKey) title = t[props.titleKey][lsLang];
  let text = t[props.trnKey][lsLang];

  if (props.confirmButtonKey) {
    let confirmButtonText = t[props.confirmButtonKey][lsLang];

    Swal.fire({
      icon: props.icon,
      title: title,
      text: text,
      confirmButtonText: confirmButtonText,
    }).then((result) => {
      if (props.onClick) {
        if (result.isConfirmed) props.onClick();
      }
    });
  } else {
    Swal.fire({
      icon: props.icon,
      title: title,
      text: text,
    });
  }
};

export default Alert;
