import $ from 'jquery';
export interface Lang {
  ja: string;
  en: string;
  cn: string;
}
export interface Tran {
  // トップページ
  catchCopy: Lang;
  mainTopic: Lang;
  highlights: Lang;
  heilights_note: Lang;
  mainDescription: Lang;
  topMessageUpper: Lang;
  topMessageLower: Lang;
  exhibition: Lang;
  exhibitionPast: Lang;
  exhibitionPastHeading: Lang;
  exhibitionDate: Lang;
  exhibitionPlace: Lang;
  special: Lang;
  specialContent: Lang;
  freeword: Lang;
  category: Lang;
  hashtag: Lang;
  abc: Lang;
  prefecture: Lang;
  search: Lang;
  more: Lang;
  adList: Lang;
  sfList: Lang;
  meeting: Lang;
  categoryProduct: Lang;
  searchFreeword: Lang;
  searchCompanyName: Lang;
  searchProductName: Lang;
  searchHashTagName: Lang;
  searchDescription: Lang;
  searchDescription2: Lang;
  nextExhibition: Lang;
  companySpecial: Lang;
  companyAttention: Lang;
  open: Lang;
  hashtagAll: Lang;
  // 検索結果ページ
  refine: Lang;
  refineHeading: Lang;
  refineShow: Lang;
  refineContent: Lang;
  refineFloor: Lang;
  refineFair: Lang;
  refineFreeword: Lang;
  refineCategory: Lang;
  refineHashtag: Lang;
  refineAbc: Lang;
  refinePrefecture: Lang;
  refineCompanyUrl: Lang;
  refineConsider: Lang;
  refineAdd: Lang;
  refineDelete: Lang;
  condition: Lang;
  condShow: Lang;
  condContent: Lang;
  condFloor: Lang;
  condFair: Lang;
  condFreeword: Lang;
  condCategory: Lang;
  condHashtag: Lang;
  condAbc: Lang;
  condPrefecture: Lang;
  result: Lang;
  prresult: Lang;
  new_flag: Lang;
  updated_flag: Lang;
  pr_flag: Lang;
  // hitNumberBefore: Lang;
  hitNumberAfter: Lang;
  resultSort1: Lang;
  resultSort2: Lang;
  resultSort3: Lang;
  resultSort4: Lang;

  // 商品検索結果ページ
  productShow: Lang;
  productPrefecture: Lang;
  productFair: Lang;
  productHashtag: Lang;
  productCategory: Lang;
  productFreeword: Lang;
  productGoods: Lang;
  productNameIndex: Lang;

  productUrl: Lang;
  productLike: Lang;
  productDate: Lang;

  //利用規約
  terms_title: Lang;
  terms_description: Lang;
  chapter1_title: Lang;
  chapter1_text1: Lang;
  chapter1_text2: Lang;
  chapter1_text3: Lang;
  chapter1_text4: Lang;
  
  chapter2_title: Lang;
  chapter2_text1: Lang;
  chapter2_text2: Lang;
  
  chapter3_title: Lang;
  chapter3_text1: Lang;
  chapter3_text2: Lang;
  chapter3_text3: Lang;
  chapter3_text4: Lang;
  chapter3_text5: Lang;
  chapter3_text6: Lang;
  
  chapter4_title: Lang;
  chapter4_text1: Lang;
  chapter4_text2: Lang;
  
  chapter5_title: Lang;
  chapter5_text1: Lang;
  chapter5_text2: Lang;
  chapter5_text3: Lang;
  chapter5_text4: Lang;
  chapter5_text5: Lang;
  chapter5_text6: Lang;
  chapter5_text7: Lang;
  chapter5_text8: Lang;
  chapter5_text9: Lang;
  chapter5_text10: Lang;
  chapter5_text11: Lang;
  chapter5_text12: Lang;
  chapter5_text13: Lang;
  chapter5_text14: Lang;
  chapter5_text15: Lang;
  chapter5_text16: Lang;
  chapter5_text17: Lang;
  
  chapter6_title: Lang;
  chapter6_text1: Lang;
  chapter6_text2: Lang;
  chapter6_text3: Lang;
  chapter6_text4: Lang;
  chapter6_text5: Lang;
  
  chapter7_title: Lang;
  chapter7_text1: Lang;
  chapter7_text2: Lang;
  chapter7_text3: Lang;
  chapter7_text4: Lang;
  chapter7_text5: Lang;
  chapter7_text6: Lang;
  
  chapter8_title: Lang;
  chapter8_text1: Lang;
  chapter8_text2: Lang;
  chapter8_text3: Lang;
  chapter8_text4: Lang;
  chapter8_text5: Lang;
  chapter8_text6: Lang;
  chapter8_text7: Lang;
  
  chapter9_title: Lang;
  chapter9_text1: Lang;
  
  chapter10_title: Lang;
  chapter10_text1: Lang;
  chapter10_text2: Lang;
  chapter10_text3: Lang;
  chapter10_text4: Lang;
  
  chapter11_title: Lang;
  chapter11_text1: Lang;
  
  chapter12_title: Lang;
  chapter12_text1: Lang;
  
  chapter13_title: Lang;
  chapter13_text1: Lang;
  
  chapter14_title: Lang;
  chapter14_text1: Lang;
  
  chapter15_title: Lang;
  chapter15_text1: Lang;
  
  chapter16_title: Lang;
  chapter16_text1: Lang;
  chapter16_text2: Lang;

  // 共通
  searchPlaceholder: Lang;
  first: Lang;
  guide: Lang;
  registration: Lang;
  buyer: Lang;
  login: Lang;
  mypage: Lang;
  favorite: Lang;
  favoriteList: Lang;
  logout: Lang;
  loading: Lang;
  wait: Lang;
  all: Lang;
  unselect: Lang;
  question: Lang;
  howtoGuide: Lang;
  //Footer
  contact: Lang;
  rule: Lang;
  terms: Lang;
  privacy: Lang;
  company: Lang;
  sampleGuidance: Lang;
  sampleRegister: Lang;
  // エラーメッセージ
  errFreeword: Lang;
  errCategory: Lang;
  errHashtag: Lang;
  errAbc: Lang;
  errPrefecture: Lang;
  errGetData: Lang;
  errReload: Lang;
  errNeedLogin: Lang;
  errRegisterFavorite: Lang;
  errRemoveFavorite: Lang;
  errSearchCondition: Lang;
}

export const t: Tran = {
  // トップページ
  mainTopic: {
    ja: '開催中の見本市',
    en: 'Now being held',
    cn: '目前正在举行',
  },
  mainDescription: {
    ja: 'プレミアム・インセンティブショーの詳細はこちら',
    en: 'Click here for details on the Premium Incentive Show',
    cn: '了解更多关于高级奖励展的信息',
  },
  catchCopy: {
    ja: 'ビジネスガイド社主催見本市『出展社商品情報ポータルサイト』',
    en: 'Portal Site by Business Guide-sha, Inc.',
    cn: '商业指南公司“参展商产品信息门户网站”主办的交易会',
  },
  highlights: {
    ja: '出展社・商品PR番組　ギフト・ショーTV',
    en: 'Gift Show TV',
    cn: 'Gift Show TV',
  },
  heilights_note: {
    ja: 'ギフト・ショーTV 番組表',
    en: 'ギフト・ショーTV 番組表',
    cn: 'ギフト・ショーTV 番組表',
  },
  topMessageUpper: {
    ja: '新たなビジネスを創出する',
    en: 'BtoB Platform of Gift Show exhibitors & products',
    cn: '创造新的业务',
  },
  topMessageLower: {
    ja: '流通業界のB to Bポータルサイト',
    en: '',
    cn: '',
  },
  searchFreeword: {
    ja: 'フリーワード検索',
    en: 'Free word search',
    cn: '自由词搜索',
  },
  searchCompanyName: {
    ja: '出展社名で検索',
    en: 'Search by exhibitor name',
    cn: '按参展商名称搜索',
  },
  searchProductName: {
    ja: '商品名で検索',
    en: 'Search by product name',
    cn: '按产品名称搜索',
  },
  searchHashTagName: {
    ja: 'ハッシュタグで検索',
    en: 'Search by hashtag',
    cn: '通过标签搜索',
  },
  searchDescription: {
    ja:
      '「フリーワード検索」、「出展社名で検索」、「商品名で検索」、「ハッシュタグで検索」等を上記プルダウンから選択の上、ご入力してください。',
    en:
      'Information of Exhibitions the selection table from "Search by free word search", "Search by company name", "Search by product name", "Search by hashtag", etc.',
    cn:
      '请在上面的下拉菜单中选择 "自由词搜索"、"按参展商名称搜索"、"按产品名称搜索"、"通过标签搜索 "等。',
  },
  searchDescription2: {
    ja:
      'New！8月21日（水）出展社情報を更新しました。',
    en:
      '[8/21(wed)]New！Exhibitor’s information has been updated.',
    cn:
      'New！8月21日（水）出展社情報を更新しました。',
  },
  nextExhibition: {
    ja:
      '開催見本市のご案内',
    en:
      'Information of Exhibitions',
    cn:
      'Information of Exhibitions',
  },
  exhibition: {
    ja: '見本市から探す（出展社検索）',
    en: 'Search by Trade Show（Exhibitor Search）',
    cn: '按展会搜索 (搜索参展企业)',
  },
  exhibitionPast: {
    ja: '過去の見本市はこちら',
    en: 'Click Here to check past shows',
    cn: '点击这里查看过去的交易会',
  },
  exhibitionPastHeading: {
    ja: '以下は過去の見本市です。誠にありがとうございました。',
    en:
      'The following is a list of past shows. Thank you very much for visiting.',
    cn: '以下是过去的交易会。 非常感谢。',
  },
  exhibitionDate: {
    ja: '会期',
    en: 'Date',
    cn: '日期',
  },
  exhibitionPlace: {
    ja: '会場',
    en: 'Place',
    cn: '会场',
  },
  meeting: {
    ja: '産地商談会から探す',
    en: 'Local Business Matching',
    cn: '按商务会议搜索',
  },
  categoryProduct: {
    ja: 'グッズから探す（商品検索）',
    en: 'Search by Products',
    cn: '按商品搜索',
  },
  companySpecial: {
    ja: '出展社特集',
    en: 'Special Contents',
    cn: '参展商专刊',
  },
  companyAttention: {
    ja: '注目の出展社',
    en: 'Featured Exhibitors',
    cn: '特色参展商',
  },
  special: {
    ja: '特集',
    en: 'Special Feature',
    cn: '特辑',
  },
  specialContent: {
    ja: '特集',
    en: 'Special Feature',
    cn: '特辑',
  },
  freeword: {
    ja: 'フリーワード検索',
    en: 'Free word search',
    cn: '自由词搜索',
  },
  category: {
    ja: '取り扱い品目から探す（出展社検索）',
    en: 'Search by Product Category（Exhibitor Search）',
    cn: '按产品范围搜索（展商搜索）',
  },
  hashtag: {
    ja: '人気のハッシュタグから探す（商品検索）',
    en: 'Search by hashtag（Product Search）',
    cn: '按流行的标签搜索（产品搜索）',
  },
  abc: {
    ja: '出展社名の頭文字から探す（出展社検索）',
    en: 'Alphabetical Order Search（Exhibitor Search）',
    cn: '按字母顺序搜索（搜索参展商）',
  },
  prefecture: {
    ja: '出展社の所在地から探す（出展社検索）',
    en: 'Search by prefecture（Exhibitor Search）',
    cn: '按参展商位置搜索（参展商定位器）',
  },
  search: {
    ja: '検索',
    en: 'Search',
    cn: '搜索',
  },
  more: {
    ja: 'もっと見る',
    en: 'View More',
    cn: '查看更多',
  },
  adList: {
    ja: '注目の出展社',
    en: 'Pick up',
    cn: '特色参展商',
  },
  sfList: {
    ja: 'ビジネスガイド社からのお知らせ',
    en: 'Information',
    cn: '通知',
  },
  open: {
    ja: '開催中',
    en: 'open',
    cn: '打开',
  },
  hashtagAll: {
    ja: '全てのハッシュタグを見る',
    en: 'View all hashtags',
    cn: '查看所有主题标签',
  },
  // 検索結果ページ
  refine: {
    ja: 'この条件で再検索',
    en: 'Search again',
    cn: '使用此条件再次搜索',
  },
  refineHeading: {
    ja: '出展社再検索',
    en: 'Exhibitor re-search',
    cn: '展商调研',
  },
  refineShow: {
    ja: '出展見本市',
    en: 'Exhibition',
    cn: '参展商展览会',
  },
  refineContent: {
    ja: '特集選択',
    en: 'Content selection',
    cn: '特色选择',
  },
  refineFloor: {
    ja: 'フロア選択',
    en: 'Floor selection',
    cn: '楼层选择',
  },
  refineFair: {
    ja: '出展フェア',
    en: 'Fair',
    cn: '展览交易会',
  },
  refineFreeword: {
    ja: 'フリーワード',
    en: 'Free Word Search',
    cn: '自由词',
  },
  refineCategory: {
    ja: '取り扱い品目：',
    en: 'Category：',
    cn: '类别：',
  },
  refineHashtag: {
    ja: 'ハッシュタグ：',
    en: 'Hashtag：',
    cn: '井号：',
  },
  refineAbc: {
    ja: '出展社名の頭文字',
    en: 'Alphabetical Order Search',
    cn: '按字母顺序搜索',
  },
  refinePrefecture: {
    ja: '出展社の所在地',
    en: 'Exhibitor location',
    cn: '参展商的位置',
  },
  refineCompanyUrl: {
    ja: '出展社ページへ',
    en: 'Exhibitor Page',
    cn: '前往参展商页面',
  },
  refineConsider: {
    ja: '検討リスト',
    en: 'Consider List',
    cn: '审查清单',
  },
  refineAdd: {
    ja: 'に追加',
    en: 'Add',
    cn: '添加',
  },
  refineDelete: {
    ja: 'から削除',
    en: 'Delete',
    cn: '删除',
  },
  condition: {
    ja: '今回の検索条件',
    en: 'Search Conditions',
    cn: '本次搜索条件',
  },
  condShow: {
    ja: 'ショー',
    en: 'Show',
    cn: '展览',
  },
  condContent: {
    ja: '特集',
    en: 'content',
    cn: '特色',
  },
  condFloor: {
    ja: 'フロア',
    en: 'Floor',
    cn: '楼层',
  },
  condFair: {
    ja: 'フェア',
    en: 'Fair',
    cn: '交易会',
  },
  condFreeword: {
    ja: 'フリーワード',
    en: 'Free Word',
    cn: '自由词',
  },
  condCategory: {
    ja: '商品カテゴリ',
    en: 'Product Category',
    cn: '商品类别',
  },
  condHashtag: {
    ja: 'ハッシュタグ',
    en: 'Hashtag',
    cn: '井号',
  },
  condAbc: {
    ja: 'アイウエオ・ABC順',
    en: 'Alphabetical Order Search',
    cn: '按音节表搜索', // todo
  },
  condPrefecture: {
    ja: '都道府県',
    en: 'Search by prefecture',
    cn: '按地区搜索',
  },
  result: {
    ja: '検索結果',
    en: 'Search result',
    cn: '搜索结果',
  },
  prresult: {
    ja: 'Gift Net choice‼︎【PR】',
    en: 'Gift Net choice‼︎【PR】',
    cn: 'Gift Net choice‼︎【PR】',
  },
  hitNumberAfter: {
    ja: '件',
    en: 'Hits',
    cn: '命中',
  },
  resultSort1: {
    ja: '項目登録が多い順',
    en: 'Most to least content entry',
    cn: 'Most to least content entry',
  },
  resultSort2: {
    ja: '新着順',
    en: 'New arrival order',
    cn: 'New arrival order',
  },
  resultSort3: {
    ja: '更新順',
    en: 'Updated order',
    cn: 'Updated order',
  },
  resultSort4: {
    ja: 'ブース番号順',
    en: 'Booth Number order',
    cn: 'Booth Number order',
  },
  new_flag: {
    ja: '新着',
    en: 'New',
    cn: '新的',
  },
  updated_flag: {
    ja: '更新',
    en: 'Updated',
    cn: '更新',
  },
  pr_flag: {
    ja: 'PR',
    en: 'PR',
    cn: 'PR',
  },
  // お気に入りページ
  favoriteList: {
    ja: 'お気に入り一覧',
    en: 'My Favorite List',
    cn: '最喜欢的名单',
  },
  // 共通
  searchPlaceholder: {
    ja: 'フリーワード検索',
    en: 'Free Word',
    cn: '自由词搜索',
  },
  first: {
    ja: 'メニュー',
    en: 'Menu',
    cn: '菜单',
  },
  guide: {
    ja: '使い方ガイド',
    en: 'How to Use Guide',
    cn: '使用指南',
  },
  registration: {
    ja: '新規登録',
    en: 'Join Free',
    cn: '新注册',
  },
  buyer: {
    ja: 'バイヤーズガイド',
    en: 'Buyer’s Guide',
    cn: '买家指南',
  },
  login: {
    ja: 'ログイン/新規登録',
    en: 'Login / Join Free',
    cn: '登录/新注册',
  },
  mypage: {
    ja: 'マイページ',
    en: 'My page',
    cn: '我的页面',
  },
  favorite: {
    ja: 'お気に入りの企業',
    en: 'My Favorite Companies',
    cn: '我们最喜欢的公司',
  },
  logout: {
    ja: 'ログアウト',
    en: 'Logout',
    cn: '登出',
  },
  loading: {
    ja: '読み込み中です',
    en: 'Loading',
    cn: '正在加载',
  },
  wait: {
    ja: '少々お待ちください',
    en: 'Just a moment, please.',
    cn: '请稍等片刻',
  },
  all: {
    ja: 'すべて',
    en: 'All',
    cn: '全部',
  },
  unselect: {
    ja: '未選択',
    en: 'unselected',
    cn: '未选择',
  },
  question: {
    ja: 'よくあるご質問',
    en: 'FaQ',
    cn: '常见问题',
  },
  howtoGuide: {
    ja: '使い方ガイド',
    en: 'Guide',
    cn: '如何使用指南',
  },
  // Footer
  contact: {
    ja: 'お問い合わせ',
    en: 'Contact',
    cn: '联系我们',
  },
  rule: {
    ja: '利用規約',
    en: 'Terms of Service',
    cn: '使用条款',
  },
  terms: {
    ja: '利用規約',
    en: 'Teams',
    cn: 'Teams',
  },
  privacy: {
    ja: 'プライバシーポリシー',
    en: 'Privacy Policy',
    cn: '隐私政策',
  },
  company: {
    ja: '会社概要',
    en: 'Company Profile',
    cn: '公司介绍',
  },
  sampleGuidance: {
    ja: '見本市出展案内',
    en: 'Trade fair information',
    cn: '交易会展览信息',
  },
  sampleRegister: {
    ja: '見本市招待状請求',
    en: 'Trade fair visit registration',
    cn: '贸易展览会参观登记',
  },
  // 商品検索ページ
  productShow: {
    ja: '出展見本市',
    en: 'Exhibition',
    cn: '参展商展览会',
  },
  productPrefecture: {
    ja: '出展社の所在地',
    en: 'Exhibition location',
    cn: '展览地点',
  },
  productFair: {
    ja: '出展フェア',
    en: 'Exhibition Fair',
    cn: '展览交易会',
  },
  productHashtag: {
    ja: 'ハッシュタグ選択',
    en: 'Hashtag',
    cn: '选择一个标签',
  },
  productGoods: {
    ja: 'グッズ選択',
    en: 'Goods',
    cn: '选择你的货物',
  },
  productCategory: {
    ja: '取り扱い品目',
    en: 'Category',
    cn: '类别',
  },
  productFreeword: {
    ja: 'フリーワード',
    en: 'Freeword',
    cn: '自由词',
  },

  productNameIndex: {
    ja: '出展社の頭文字',
    en: 'Alphabet',
    cn: '按音节',
  },

  productUrl: {
    ja: '商品ページへ',
    en: 'Product Page',
    cn: '商品页面',
  },
  productLike: {
    ja: 'いいね',
    en: 'Like',
    cn: '赞',
  },
  productDate: {
    ja: '最終更新日',
    en: 'Updated at',
    cn: '最后更新于',
  },

  //利用規約
  terms_title: {
    ja: 'Gift Net利用規約',
    en: 'Terms of Gift Net',
    cn: 'Terms of Gift Net',
  },
  terms_description:{
    ja: 'この利用規約（以下，「本規約」といいます。）は，株式会社ビジネスガイド社（以下，「当社」といいます。）が，このウェブサイト上で出展社に関する情報等を提供するサービス（以下，「本サービス」といい，その詳細は第２条で定めます。）の利用条件を定めるものです。登録お客様の皆さま（以下，「お客様」といいます。）には，本規約に従って，本サービスをご利用いただきます。',
    en: 'この利用規約（以下，「本規約」といいます。）は，株式会社ビジネスガイド社（以下，「当社」といいます。）が，このウェブサイト上で出展社に関する情報等を提供するサービス（以下，「本サービス」といい，その詳細は第２条で定めます。）の利用条件を定めるものです。登録お客様の皆さま（以下，「お客様」といいます。）には，本規約に従って，本サービスをご利用いただきます。',
    cn: 'この利用規約（以下，「本規約」といいます。）は，株式会社ビジネスガイド社（以下，「当社」といいます。）が，このウェブサイト上で出展社に関する情報等を提供するサービス（以下，「本サービス」といい，その詳細は第２条で定めます。）の利用条件を定めるものです。登録お客様の皆さま（以下，「お客様」といいます。）には，本規約に従って，本サービスをご利用いただきます。',
  },
  chapter1_title:{
    ja: '第1条（適用）',
    en: '第1条（適用）',
    cn: '第1条（適用）',
  },
chapter1_text1:{
    ja: '本規約は，お客様と当社との間の本サービスの利用に関わる一切の関係に適用されるものとします。',
    en: '本規約は，お客様と当社との間の本サービスの利用に関わる一切の関係に適用されるものとします。',
    cn: '本規約は，お客様と当社との間の本サービスの利用に関わる一切の関係に適用されるものとします。',
  },
chapter1_text2:{
    ja: 'お客様が本サービスをご利用いただくには，本規約の全ての条項に同意をし，本規約を遵守しなければならず，第３条の利用登録が完了したことをもって，本規約の全ての条項に同意をしたものとみなされます。',
    en: 'お客様が本サービスをご利用いただくには，本規約の全ての条項に同意をし，本規約を遵守しなければならず，第３条の利用登録が完了したことをもって，本規約の全ての条項に同意をしたものとみなされます。',
    cn: 'お客様が本サービスをご利用いただくには，本規約の全ての条項に同意をし，本規約を遵守しなければならず，第３条の利用登録が完了したことをもって，本規約の全ての条項に同意をしたものとみなされます。',
  },
chapter1_text3:{
    ja: '当社は本サービスに関し，本規約のほか，ご利用にあたってのルール等，各種の定め（以下，「個別規定」といいます。）をすることがあります。これら個別規定はその名称のいかんに関わらず，本規約の一部を構成するものとします。',
    en: '当社は本サービスに関し，本規約のほか，ご利用にあたってのルール等，各種の定め（以下，「個別規定」といいます。）をすることがあります。これら個別規定はその名称のいかんに関わらず，本規約の一部を構成するものとします。',
    cn: '当社は本サービスに関し，本規約のほか，ご利用にあたってのルール等，各種の定め（以下，「個別規定」といいます。）をすることがあります。これら個別規定はその名称のいかんに関わらず，本規約の一部を構成するものとします。',
  },
chapter1_text4:{
    ja: '本規約の規定が前条の個別規定の規定と矛盾する場合には，個別規定において特段の定めなき限り，個別規定の規定が優先されるものとします。',
    en: '本規約の規定が前条の個別規定の規定と矛盾する場合には，個別規定において特段の定めなき限り，個別規定の規定が優先されるものとします。',
    cn: '本規約の規定が前条の個別規定の規定と矛盾する場合には，個別規定において特段の定めなき限り，個別規定の規定が優先されるものとします。',
  },

chapter2_title:{
    ja: '第２条（本サービスの概要，利用条件）',
    en: '第２条（本サービスの概要，利用条件）',
    cn: '第２条（本サービスの概要，利用条件）',
  },
chapter2_text1:{
    ja: '本サービスは，当社が，お客様に対して，このウェブサイト上で，当社が開催（主催，共催）する展示会へ出展を予定している出展社（以下，「出展社」といいます。）から提供を受けた出展者に関する情報および出展者の商品に関する情報等（以下，「出展社情報」といいます。）を提供するサービスです。但し，当社は，出展者情報の正確性等については明示的にも黙示的にも保証するものではありません。',
    en: '本サービスは，当社が，お客様に対して，このウェブサイト上で，当社が開催（主催，共催）する展示会へ出展を予定している出展社（以下，「出展社」といいます。）から提供を受けた出展者に関する情報および出展者の商品に関する情報等（以下，「出展社情報」といいます。）を提供するサービスです。但し，当社は，出展者情報の正確性等については明示的にも黙示的にも保証するものではありません。',
    cn: '本サービスは，当社が，お客様に対して，このウェブサイト上で，当社が開催（主催，共催）する展示会へ出展を予定している出展社（以下，「出展社」といいます。）から提供を受けた出展者に関する情報および出展者の商品に関する情報等（以下，「出展社情報」といいます。）を提供するサービスです。但し，当社は，出展者情報の正確性等については明示的にも黙示的にも保証するものではありません。',
  },
chapter2_text2:{
    ja: 'お客様は，自己の責任でお客様自身の情報を開示した上で，本サービスを利用して出展社に対して連絡を取ることができます（以下，「問い合わせ機能」といいます）。問い合わせ機能は，お客様と出展社間の連絡の場を提供するものであり，お客様と出展社間のトラブル，問題，クレーム等について当社は一切責任を負わず，出展社との間でトラブル等が発生した場合，お客様は全て自己の責任および費用にて当該問題を解決しなければなりません。',
    en: 'お客様は，自己の責任でお客様自身の情報を開示した上で，本サービスを利用して出展社に対して連絡を取ることができます（以下，「問い合わせ機能」といいます）。問い合わせ機能は，お客様と出展社間の連絡の場を提供するものであり，お客様と出展社間のトラブル，問題，クレーム等について当社は一切責任を負わず，出展社との間でトラブル等が発生した場合，お客様は全て自己の責任および費用にて当該問題を解決しなければなりません。',
    cn: 'お客様は，自己の責任でお客様自身の情報を開示した上で，本サービスを利用して出展社に対して連絡を取ることができます（以下，「問い合わせ機能」といいます）。問い合わせ機能は，お客様と出展社間の連絡の場を提供するものであり，お客様と出展社間のトラブル，問題，クレーム等について当社は一切責任を負わず，出展社との間でトラブル等が発生した場合，お客様は全て自己の責任および費用にて当該問題を解決しなければなりません。',
  },

chapter3_title:{
    ja: '第３条（利用登録）',
    en: '第３条（利用登録）',
    cn: '第３条（利用登録）',
  },
chapter3_text1:{
    ja: '本サービスにおいては，登録希望者が本規約に同意の上，当社の定める方法によって利用登録を申請し，当社がこれを承認することによって，利用登録が完了するものとします。',
    en: '本サービスにおいては，登録希望者が本規約に同意の上，当社の定める方法によって利用登録を申請し，当社がこれを承認することによって，利用登録が完了するものとします。',
    cn: '本サービスにおいては，登録希望者が本規約に同意の上，当社の定める方法によって利用登録を申請し，当社がこれを承認することによって，利用登録が完了するものとします。',
  },
chapter3_text2:{
    ja: '当社は，利用登録の申請者に以下の事由があると判断した場合，利用登録の申請を承認しないことがあり，その理由については一切の開示義務を負わないものとします。',
    en: '当社は，利用登録の申請者に以下の事由があると判断した場合，利用登録の申請を承認しないことがあり，その理由については一切の開示義務を負わないものとします。',
    cn: '当社は，利用登録の申請者に以下の事由があると判断した場合，利用登録の申請を承認しないことがあり，その理由については一切の開示義務を負わないものとします。',
  },
chapter3_text3:{
    ja: '・利用登録の申請に際して虚偽の事項を届け出た場合',
    en: '・利用登録の申請に際して虚偽の事項を届け出た場合',
    cn: '・利用登録の申請に際して虚偽の事項を届け出た場合',
  },
chapter3_text4:{
    ja: '・過去に本サービスまたは当社が提供するサービス（展示会開催を含みますが，これに限られません。）において，当社から警告，利用中止，取引停止その他これに相当する処分を受けている者であった場合',
    en: '・過去に本サービスまたは当社が提供するサービス（展示会開催を含みますが，これに限られません。）において，当社から警告，利用中止，取引停止その他これに相当する処分を受けている者であった場合',
    cn: '・過去に本サービスまたは当社が提供するサービス（展示会開催を含みますが，これに限られません。）において，当社から警告，利用中止，取引停止その他これに相当する処分を受けている者であった場合',
  },
chapter3_text5:{
    ja: '・反社会的勢力（暴力団，暴力団員，右翼団体，反社会的勢力，その他これに準ずる者を意味します。以下同じ。）の構成員である，または，反社会的勢力等との何らかの交流若しくは関与を行っていると当社が判断した場合',
    en: '・反社会的勢力（暴力団，暴力団員，右翼団体，反社会的勢力，その他これに準ずる者を意味します。以下同じ。）の構成員である，または，反社会的勢力等との何らかの交流若しくは関与を行っていると当社が判断した場合',
    cn: '・反社会的勢力（暴力団，暴力団員，右翼団体，反社会的勢力，その他これに準ずる者を意味します。以下同じ。）の構成員である，または，反社会的勢力等との何らかの交流若しくは関与を行っていると当社が判断した場合',
  },
chapter3_text6:{
    ja: '・その他，当社が利用登録を相当でないと判断した場合',
    en: '・その他，当社が利用登録を相当でないと判断した場合',
    cn: '・その他，当社が利用登録を相当でないと判断した場合',
  },

chapter4_title:{
    ja: '第４条（閲覧用IDおよびパスワードの管理）',
    en: '第４条（閲覧用IDおよびパスワードの管理）',
    cn: '第４条（閲覧用IDおよびパスワードの管理）',
  },
chapter4_text1:{
    ja: 'お客様は，自己の責任において，本サービスの登録IDおよびパスワードを適切に管理するものとします。',
    en: 'お客様は，自己の責任において，本サービスの登録IDおよびパスワードを適切に管理するものとします。',
    cn: 'お客様は，自己の責任において，本サービスの登録IDおよびパスワードを適切に管理するものとします。',
  },
chapter4_text2:{
    ja: 'お客様は，いかなる場合にも，閲覧用IDおよびパスワードを第三者に譲渡または貸与し，もしくは第三者と共用することはできません。',
    en: 'お客様は，いかなる場合にも，閲覧用IDおよびパスワードを第三者に譲渡または貸与し，もしくは第三者と共用することはできません。',
    cn: 'お客様は，いかなる場合にも，閲覧用IDおよびパスワードを第三者に譲渡または貸与し，もしくは第三者と共用することはできません。',
  },

chapter5_title:{
    ja: '第５条（禁止事項）',
    en: '第５条（禁止事項）',
    cn: '第５条（禁止事項）',
  },
chapter5_text1:{
    ja: 'お客様は，本サービスの利用にあたり，以下の行為をしてはなりません。',
    en: 'お客様は，本サービスの利用にあたり，以下の行為をしてはなりません。',
    cn: 'お客様は，本サービスの利用にあたり，以下の行為をしてはなりません。',
  },
chapter5_text2:{
    ja: '・出展社情報を，本サービス利用以外の目的で利用する行為',
    en: '・出展社情報を，本サービス利用以外の目的で利用する行為',
    cn: '・出展社情報を，本サービス利用以外の目的で利用する行為',
  },
chapter5_text3:{
    ja: '・法令または公序良俗に違反する行為',
    en: '・法令または公序良俗に違反する行為',
    cn: '・法令または公序良俗に違反する行為',
  },
chapter5_text4:{
    ja: '・犯罪行為に関連する行為',
    en: '・犯罪行為に関連する行為',
    cn: '・犯罪行為に関連する行為',
  },
chapter5_text5:{
    ja: '・本サービスの内容等，本サービスに含まれる著作権，商標権ほか，当社および出展者の保有する知的財産権を侵害する行為，または侵害するおそれのある行為',
    en: '・本サービスの内容等，本サービスに含まれる著作権，商標権ほか，当社および出展者の保有する知的財産権を侵害する行為，または侵害するおそれのある行為',
    cn: '・本サービスの内容等，本サービスに含まれる著作権，商標権ほか，当社および出展者の保有する知的財産権を侵害する行為，または侵害するおそれのある行為',
  },
chapter5_text6:{
    ja: '・当社，ほかのお客様，またはその他第三者のサーバーまたはネットワークの機能を破壊したり，妨害したりする行為',
    en: '・当社，ほかのお客様，またはその他第三者のサーバーまたはネットワークの機能を破壊したり，妨害したりする行為',
    cn: '・当社，ほかのお客様，またはその他第三者のサーバーまたはネットワークの機能を破壊したり，妨害したりする行為',
  },
chapter5_text7:{
    ja: '・当社のサービスの運営，出展社の業務を妨害するおそれのある行為',
    en: '・当社のサービスの運営，出展社の業務を妨害するおそれのある行為',
    cn: '・当社のサービスの運営，出展社の業務を妨害するおそれのある行為',
  },
chapter5_text8:{
    ja: '・本規約に違反する行為',
    en: '・本規約に違反する行為',
    cn: '・本規約に違反する行為',
  },
chapter5_text9:{
    ja: '・不正アクセスをし，またはこれを試みる行為',
    en: '・不正アクセスをし，またはこれを試みる行為',
    cn: '・不正アクセスをし，またはこれを試みる行為',
  },
chapter5_text10:{
    ja: '・他のお客様，出展社に関する個人情報等を収集または蓄積する行為',
    en: '・他のお客様，出展社に関する個人情報等を収集または蓄積する行為',
    cn: '・他のお客様，出展社に関する個人情報等を収集または蓄積する行為',
  },
chapter5_text11:{
    ja: '・不正な目的を持って本サービスを利用する行為',
    en: '・不正な目的を持って本サービスを利用する行為',
    cn: '・不正な目的を持って本サービスを利用する行為',
  },
chapter5_text12:{
    ja: '・本サービスの他のお客様，出展社，またはその他の第三者に不利益，損害，不快感を与える行為',
    en: '・本サービスの他のお客様，出展社，またはその他の第三者に不利益，損害，不快感を与える行為',
    cn: '・本サービスの他のお客様，出展社，またはその他の第三者に不利益，損害，不快感を与える行為',
  },
chapter5_text13:{
    ja: '・他のお客様に成りすます行為',
    en: '・他のお客様に成りすます行為',
    cn: '・他のお客様に成りすます行為',
  },
chapter5_text14:{
    ja: '・当社が許諾しない本サービス上での宣伝，広告，勧誘，または営業行為',
    en: '・当社が許諾しない本サービス上での宣伝，広告，勧誘，または営業行為',
    cn: '・当社が許諾しない本サービス上での宣伝，広告，勧誘，または営業行為',
  },
chapter5_text15:{
    ja: '・面識のない異性との出会いを目的とした行為',
    en: '・面識のない異性との出会いを目的とした行為',
    cn: '・面識のない異性との出会いを目的とした行為',
  },
chapter5_text16:{
    ja: '・当社のサービスに関連して，反社会的勢力に対して直接または間接に利益を供与する行為',
    en: '・当社のサービスに関連して，反社会的勢力に対して直接または間接に利益を供与する行為',
    cn: '・当社のサービスに関連して，反社会的勢力に対して直接または間接に利益を供与する行為',
  },
chapter5_text17:{
    ja: '・その他，当社が不適切と判断する行為',
    en: '・その他，当社が不適切と判断する行為',
    cn: '・その他，当社が不適切と判断する行為',
  },

chapter6_title:{
    ja: '第６条（問い合わせ機能利用の際の諸注意）',
    en: '第６条（問い合わせ機能利用の際の諸注意）',
    cn: '第６条（問い合わせ機能利用の際の諸注意）',
  },
chapter6_text1:{
    ja: 'お客様は，問い合わせ機能を利用して，お客様自身の情報を出展社に提供する場合，以下の条件等について予め了承するものとします。',
    en: 'お客様は，問い合わせ機能を利用して，お客様自身の情報を出展社に提供する場合，以下の条件等について予め了承するものとします。',
    cn: 'お客様は，問い合わせ機能を利用して，お客様自身の情報を出展社に提供する場合，以下の条件等について予め了承するものとします。',
  },
chapter6_text2:{
    ja: '・問い合わせ機能を利用する際の出展社情報の正確性については，当社が保証するものではないこと',
    en: '・問い合わせ機能を利用する際の出展社情報の正確性については，当社が保証するものではないこと',
    cn: '・問い合わせ機能を利用する際の出展社情報の正確性については，当社が保証するものではないこと',
  },
chapter6_text3:{
    ja: '・お客様が提供する情報は，そのまま出展者へ提供され，その正確性については，お客様が全ての責任を負うこと',
    en: '・お客様が提供する情報は，そのまま出展者へ提供され，その正確性については，お客様が全ての責任を負うこと',
    cn: '・お客様が提供する情報は，そのまま出展者へ提供され，その正確性については，お客様が全ての責任を負うこと',
  },
chapter6_text4:{
    ja: '・お客様と出展者間のトラブル，問題，クレーム等（以下，「トラブル等」といいます。）について当社は一切責任を負わず，出展社との間でトラブル等が発生した場合，お客様は全て自己の責任および費用にて当該問題を解決しなければならないこと',
    en: '・お客様と出展者間のトラブル，問題，クレーム等（以下，「トラブル等」といいます。）について当社は一切責任を負わず，出展社との間でトラブル等が発生した場合，お客様は全て自己の責任および費用にて当該問題を解決しなければならないこと',
    cn: '・お客様と出展者間のトラブル，問題，クレーム等（以下，「トラブル等」といいます。）について当社は一切責任を負わず，出展社との間でトラブル等が発生した場合，お客様は全て自己の責任および費用にて当該問題を解決しなければならないこと',
  },
chapter6_text5:{
    ja: '・お客様と出展者間のトラブル等に当社が対応するために，お客様の個人情報等を当該出展者様に対して開示する可能性があること',
    en: '・お客様と出展者間のトラブル等に当社が対応するために，お客様の個人情報等を当該出展者様に対して開示する可能性があること',
    cn: '・お客様と出展者間のトラブル等に当社が対応するために，お客様の個人情報等を当該出展者様に対して開示する可能性があること',
  },

chapter7_title:{
    ja: '第７条（本サービスの提供の停止等）',
    en: '第７条（本サービスの提供の停止等）',
    cn: '第７条（本サービスの提供の停止等）',
  },
chapter7_text1:{
    ja: '当社は，以下のいずれかの事由があると判断した場合，お客様に事前に通知することなく本サービスの全部または一部の提供を停止または中断することができるものとします。',
    en: '当社は，以下のいずれかの事由があると判断した場合，お客様に事前に通知することなく本サービスの全部または一部の提供を停止または中断することができるものとします。',
    cn: '当社は，以下のいずれかの事由があると判断した場合，お客様に事前に通知することなく本サービスの全部または一部の提供を停止または中断することができるものとします。',
  },
chapter7_text2:{
    ja: '・本サービスにかかるコンピュータシステムの保守点検または更新を行う場合',
    en: '・本サービスにかかるコンピュータシステムの保守点検または更新を行う場合',
    cn: '・本サービスにかかるコンピュータシステムの保守点検または更新を行う場合',
  },
chapter7_text3:{
    ja: '・地震，落雷，火災，停電または天災などの不可抗力により，本サービスの提供が困難となった場合',
    en: '・地震，落雷，火災，停電または天災などの不可抗力により，本サービスの提供が困難となった場合',
    cn: '・地震，落雷，火災，停電または天災などの不可抗力により，本サービスの提供が困難となった場合',
  },
chapter7_text4:{
    ja: '・コンピュータまたは通信回線等が事故により停止した場合',
    en: '・コンピュータまたは通信回線等が事故により停止した場合',
    cn: '・コンピュータまたは通信回線等が事故により停止した場合',
  },
chapter7_text5:{
    ja: '・その他，当社が本サービスの提供が困難と判断した場合',
    en: '・その他，当社が本サービスの提供が困難と判断した場合',
    cn: '・その他，当社が本サービスの提供が困難と判断した場合',
  },
chapter7_text6:{
    ja: '当社は，本サービスの提供の停止または中断により，お客様または第三者が被ったいかなる不利益または損害についても，一切の責任を負わないものとします。',
    en: '当社は，本サービスの提供の停止または中断により，お客様または第三者が被ったいかなる不利益または損害についても，一切の責任を負わないものとします。',
    cn: '当社は，本サービスの提供の停止または中断により，お客様または第三者が被ったいかなる不利益または損害についても，一切の責任を負わないものとします。',
  },

chapter8_title:{
    ja: '第８条（利用制限および登録抹消）',
    en: '第８条（利用制限および登録抹消）',
    cn: '第８条（利用制限および登録抹消）',
  },
chapter8_text1:{
    ja: '当社は，お客様が以下のいずれかに該当する場合には，事前の通知なく，お客様に対して，本サービスの全部もしくは一部の利用を制限し，またはお客様としての登録を抹消することができるものとします。',
    en: '当社は，お客様が以下のいずれかに該当する場合には，事前の通知なく，お客様に対して，本サービスの全部もしくは一部の利用を制限し，またはお客様としての登録を抹消することができるものとします。',
    cn: '当社は，お客様が以下のいずれかに該当する場合には，事前の通知なく，お客様に対して，本サービスの全部もしくは一部の利用を制限し，またはお客様としての登録を抹消することができるものとします。',
  },
chapter8_text2:{
    ja: '・本規約のいずれかの条項に違反した場合',
    en: '・本規約のいずれかの条項に違反した場合',
    cn: '・本規約のいずれかの条項に違反した場合',
  },
chapter8_text3:{
    ja: '・登録事項に虚偽の事実があることが判明した場合',
    en: '・登録事項に虚偽の事実があることが判明した場合',
    cn: '・登録事項に虚偽の事実があることが判明した場合',
  },
chapter8_text4:{
    ja: '・当社からの連絡に対し，一定期間返答がない場合',
    en: '・当社からの連絡に対し，一定期間返答がない場合',
    cn: '・当社からの連絡に対し，一定期間返答がない場合',
  },
chapter8_text5:{
    ja: '・本サービスについて，最終の利用から1年間利用がない場合',
    en: '・本サービスについて，最終の利用から1年間利用がない場合',
    cn: '・本サービスについて，最終の利用から1年間利用がない場合',
  },
chapter8_text6:{
    ja: '・その他，当社が本サービスの利用を適当でないと判断した場合',
    en: '・その他，当社が本サービスの利用を適当でないと判断した場合',
    cn: '・その他，当社が本サービスの利用を適当でないと判断した場合',
  },
chapter8_text7:{
    ja: '当社は，本条に基づき当社が行った行為によりお客様に生じた損害について，一切の責任を負いません。',
    en: '当社は，本条に基づき当社が行った行為によりお客様に生じた損害について，一切の責任を負いません。',
    cn: '当社は，本条に基づき当社が行った行為によりお客様に生じた損害について，一切の責任を負いません。',
  },

chapter9_title:{
    ja: '第９条（登録抹消）',
    en: '第９条（登録抹消）',
    cn: '第９条（登録抹消）',
  },
chapter9_text1:{
    ja: 'お客様は，当社に連絡いただくことにより，本サービスご利用にあたり登録いただいた情報を抹消できるものとします。',
    en: 'お客様は，当社に連絡いただくことにより，本サービスご利用にあたり登録いただいた情報を抹消できるものとします。',
    cn: 'お客様は，当社に連絡いただくことにより，本サービスご利用にあたり登録いただいた情報を抹消できるものとします。',
  },

chapter10_title:{
    ja: '第10条（保証の否認および免責事項）',
    en: '第10条（保証の否認および免責事項）',
    cn: '第10条（保証の否認および免責事項）',
  },
chapter10_text1:{
    ja: '当社は，本サービスに事実上または法律上の瑕疵（安全性，信頼性，正確性，完全性，有効性，特定の目的への適合性，セキュリティなどに関する欠陥，エラーやバグ，権利侵害などを含みます。）がないことを明示的にも黙示的にも保証しておりません。',
    en: '当社は，本サービスに事実上または法律上の瑕疵（安全性，信頼性，正確性，完全性，有効性，特定の目的への適合性，セキュリティなどに関する欠陥，エラーやバグ，権利侵害などを含みます。）がないことを明示的にも黙示的にも保証しておりません。',
    cn: '当社は，本サービスに事実上または法律上の瑕疵（安全性，信頼性，正確性，完全性，有効性，特定の目的への適合性，セキュリティなどに関する欠陥，エラーやバグ，権利侵害などを含みます。）がないことを明示的にも黙示的にも保証しておりません。',
  },
chapter10_text2:{
    ja: '当社は，本サービスに起因してお客様に生じたあらゆる損害について，当社に故意または重過失がない限り，一切の責任を負いません。',
    en: '当社は，本サービスに起因してお客様に生じたあらゆる損害について，当社に故意または重過失がない限り，一切の責任を負いません。',
    cn: '当社は，本サービスに起因してお客様に生じたあらゆる損害について，当社に故意または重過失がない限り，一切の責任を負いません。',
  },
chapter10_text3:{
    ja: '本サービスに関連して，当社に故意または重過失があり，当社がお客様に対して損害賠償責任を負う場合，その賠償範囲は結果的損害に限られ，付属的損害，逸失利益等の間接損害，その他特別な損害については，賠償する責任を負わないものとします。',
    en: '本サービスに関連して，当社に故意または重過失があり，当社がお客様に対して損害賠償責任を負う場合，その賠償範囲は結果的損害に限られ，付属的損害，逸失利益等の間接損害，その他特別な損害については，賠償する責任を負わないものとします。',
    cn: '本サービスに関連して，当社に故意または重過失があり，当社がお客様に対して損害賠償責任を負う場合，その賠償範囲は結果的損害に限られ，付属的損害，逸失利益等の間接損害，その他特別な損害については，賠償する責任を負わないものとします。',
  },
chapter10_text4:{
    ja: '当社は，本サービスに関して，お客様と他のお客様または第三者との間において生じた取引，連絡または紛争等について一切責任を負いません。',
    en: '当社は，本サービスに関して，お客様と他のお客様または第三者との間において生じた取引，連絡または紛争等について一切責任を負いません。',
    cn: '当社は，本サービスに関して，お客様と他のお客様または第三者との間において生じた取引，連絡または紛争等について一切責任を負いません。',
  },

chapter11_title:{
    ja: '第11条（サービス内容の変更等）',
    en: '第11条（サービス内容の変更等）',
    cn: '第11条（サービス内容の変更等）',
  },
chapter11_text1:{
    ja: '当社は，お客様に通知することなく，本サービスの内容を変更しまたは本サービスの提供を中止することができるものとし，これによってお客様に生じた損害について一切の責任を負いません。',
    en: '当社は，お客様に通知することなく，本サービスの内容を変更しまたは本サービスの提供を中止することができるものとし，これによってお客様に生じた損害について一切の責任を負いません。',
    cn: '当社は，お客様に通知することなく，本サービスの内容を変更しまたは本サービスの提供を中止することができるものとし，これによってお客様に生じた損害について一切の責任を負いません。',
  },

chapter12_title:{
    ja: '第12条（利用規約の変更）',
    en: '第12条（利用規約の変更）',
    cn: '第12条（利用規約の変更）',
  },
chapter12_text1:{
    ja: '当社は，必要と判断した場合には，お客様に通知することなくいつでも本規約を変更することができるものとします。なお，本規約の変更後，本サービスの利用を開始した場合には，当該お客様は変更後の規約に同意したものとみなします。',
    en: '当社は，必要と判断した場合には，お客様に通知することなくいつでも本規約を変更することができるものとします。なお，本規約の変更後，本サービスの利用を開始した場合には，当該お客様は変更後の規約に同意したものとみなします。',
    cn: '当社は，必要と判断した場合には，お客様に通知することなくいつでも本規約を変更することができるものとします。なお，本規約の変更後，本サービスの利用を開始した場合には，当該お客様は変更後の規約に同意したものとみなします。',
  },

chapter13_title:{
    ja: '第13条（個人情報の取扱い）',
    en: '第13条（個人情報の取扱い）',
    cn: '第13条（個人情報の取扱い）',
  },
chapter13_text1:{
    ja: '当社は，本サービスの利用によって取得する個人情報については，当社「プライバシーポリシー」に従い適切に取り扱うものとします。',
    en: '当社は，本サービスの利用によって取得する個人情報については，当社「プライバシーポリシー」に従い適切に取り扱うものとします。',
    cn: '当社は，本サービスの利用によって取得する個人情報については，当社「プライバシーポリシー」に従い適切に取り扱うものとします。',
  },

chapter14_title:{
    ja: '第14条（通知または連絡）',
    en: '第14条（通知または連絡）',
    cn: '第14条（通知または連絡）',
  },
chapter14_text1:{
    ja: 'お客様と当社との間の通知または連絡は，当社の定める方法によって行うものとします。当社は,お客様から,当社が別途定める方式に従った変更届け出がない限り,現在登録されている連絡先が有効なものとみなして当該連絡先へ通知または連絡を行い,これらは,発信時にお客様へ到達したものとみなします。',
    en: 'お客様と当社との間の通知または連絡は，当社の定める方法によって行うものとします。当社は,お客様から,当社が別途定める方式に従った変更届け出がない限り,現在登録されている連絡先が有効なものとみなして当該連絡先へ通知または連絡を行い,これらは,発信時にお客様へ到達したものとみなします。',
    cn: 'お客様と当社との間の通知または連絡は，当社の定める方法によって行うものとします。当社は,お客様から,当社が別途定める方式に従った変更届け出がない限り,現在登録されている連絡先が有効なものとみなして当該連絡先へ通知または連絡を行い,これらは,発信時にお客様へ到達したものとみなします。',
  },

chapter15_title:{
    ja: '第15条（権利義務の譲渡の禁止）',
    en: '第15条（権利義務の譲渡の禁止）',
    cn: '第15条（権利義務の譲渡の禁止）',
  },
chapter15_text1:{
    ja: 'お客様は，当社の書面による事前の承諾なく，利用契約上の地位または本規約に基づく権利もしくは義務を第三者に譲渡し，または担保に供することはできません。',
    en: 'お客様は，当社の書面による事前の承諾なく，利用契約上の地位または本規約に基づく権利もしくは義務を第三者に譲渡し，または担保に供することはできません。',
    cn: 'お客様は，当社の書面による事前の承諾なく，利用契約上の地位または本規約に基づく権利もしくは義務を第三者に譲渡し，または担保に供することはできません。',
  },

chapter16_title:{
    ja: '第16条（準拠法・裁判管轄）',
    en: '第16条（準拠法・裁判管轄）',
    cn: '第16条（準拠法・裁判管轄）',
  },
chapter16_text1:{
    ja: '本規約の解釈にあたっては，日本法を準拠法とします。',
    en: '本規約の解釈にあたっては，日本法を準拠法とします。',
    cn: '本規約の解釈にあたっては，日本法を準拠法とします。',
  },
chapter16_text2:{
    ja: '本サービスに関して紛争が生じた場合には，当社の本店所在地を管轄する裁判所を専属的合意管轄とします。',
    en: '本サービスに関して紛争が生じた場合には，当社の本店所在地を管轄する裁判所を専属的合意管轄とします。',
    cn: '本サービスに関して紛争が生じた場合には，当社の本店所在地を管轄する裁判所を専属的合意管轄とします。',
  },

  // エラーメッセージ
  errFreeword: {
    ja: 'フリーワードを入力してください',
    en: 'Input text you want to find and click the search',
    cn: '请输入一个自由词',
  },
  errCategory: {
    ja: '商品カテゴリを選択してください',
    en: 'Select a product category',
    cn: '请选择一个产品类别',
  },
  errHashtag: {
    ja: 'ハッシュタグを選択してください',
    en: 'Select Hashtag',
    cn: '请选择一个标签',
  },
  errAbc: {
    ja: 'アイウエオ・ABCを選択してください',
    en: 'Select an initial letter',
    cn: '选择一个首字母',
  },
  errPrefecture: {
    ja: '都道府県を選択してください',
    en: 'Select region',
    cn: '请选择一个县',
  },
  errGetData: {
    ja: 'データの取得に失敗しました',
    en: 'Failed to retrieve data',
    cn: '检索数据失败',
  },
  errReload: {
    ja: '再読み込みする',
    en: 'Reload',
    cn: '重新加载',
  },
  errNeedLogin: {
    ja: 'ログインが必要です',
    en: 'You need to Log in',
    cn: '你需要登录',
  },
  errRegisterFavorite: {
    ja: 'お気に入りを登録するにはログインが必要です',
    en: 'You must be logged in to register your favorites',
    cn: '您必须登录后才能添加您的收藏夹',
  },
  errRemoveFavorite: {
    ja: 'お気に入りの登録解除に失敗しました',
    en: 'Failed to remove it from my favorites',
    cn: '取消订阅您的最爱失败了',
  },
  errSearchCondition: {
    ja: '検索条件を一つ以上選んでください',
    en: 'Please select one or more search criteria',
    cn: '请选择一个或多个搜索标准',
  },
};

export const translate = (lang: keyof Lang) => {
  $('.trn').each((_, e) => {
    const trn_key = $(e).data('trn-key') as keyof Tran;
    if (trn_key in t) {
      $(e).text(t[trn_key][lang]);
      if ($(e).is('input')) $(e).attr('placeholder', t[trn_key][lang]);
    }
  });
};

// 翻訳する
const run = () => {
  const lsLang = localStorage.getItem('lang') as keyof Lang | null;

  if (lsLang) {
    translate(lsLang);
    $('.trn-lang').each((_, el) => {
      const _lang = $(el).data('value') as keyof Lang;
      if (_lang === lsLang) {
        $(el).addClass('active');
      } else {
        $(el).removeClass('active');
      }
    });
  } else {
    translate('ja');
    localStorage.setItem('lang', 'ja');
  }
};

export default run;
