interface IProps {
  className: 'topsection-label';
  active: string;
  alt: string;
  src: string;
  url: string;
}

const TopSectionComponent = (props: IProps): string => {
  return `
    <div class="carousel-item ${props.active}">
        <a href="${props.url}" target="_blank">
          <img
             alt="${props.alt}"
             aria-label="Placeholder: Third slide"
             class="bd-placeholder-img bd-placeholder-img-lg d-block w-100"
             focusable="false"
             preserveAspectRatio="xMidYMid slice"
             role="img"
             src="${props.src}"
             xmlns="http://www.w3.org/2000/svg"
          />
        </a>
    </div>
    `;
};
export default TopSectionComponent;
