import $ from 'jquery';
import Alert from './util/alert';
import {
  nameIndexs,
  nameIndexs_en,
  abcNameWebIndex,
  abcNameWebIndex_en,
  defNameWebIndex,
} from './variables';
import { createParams } from './util/urlParam';
import {
  getUserJqXHR,
  getShowJqXHR,
  getCategoryJqXHR,
  getCategoryProductJqXHR,
  getProductTagJqXHR,
  getProvinceJqXHR,
  getAreaProvinceJqXHR,
  getMgJqXHR,
  getTopSectionJqXHR,
  postWelconmeToGiftNetJqXHR,
} from './api';
import {
  User,
  GiftShow,
  Category,
  CategoryProduct,
  ProductHashTag,
  Province,
  AreaProvince,
  Meeting,
  TopSectionSearchType,
  TopSection,
} from './model/index';
import translate, { Lang } from './util/translate';
import { login, logout } from './util/auth';
import HashtagLabelComponent from './components/HashtagLabelComponents';
import AreaPrefectureComponent from './components/AreaPrefectureComponent';
import GiftShowComponent from './components/GiftShowComponent';
import CategoryComponent from './components/CategoryComponent';
import CategoryProductComponent from './components/CategoryProductComponent';
import AbcComponent from './components/AbcComponent';
import MeetingComponent from './components/MeetingComponent';
import TopSectionComponent from './components/TopSectionComponent';

import { checkBreakPointGiftshow, checkSlickMeeting } from './util/slick';

$(() => {
  $('#loading').show(); // ロードを開始
  translate(); // 翻訳

  // 言語
  const lang = (localStorage.getItem('lang') ?? 'ja') as keyof Lang;

  $('.login').on('click', login);
  $('.logout').on('click', logout);

  // ユーザー情報 取得
  const userJqXHR = getUserJqXHR();
  userJqXHR
    .done((user: User) => {
      $('#profile-username').text(`${user.name} 様`);
      $('.profile-authorized a').show();
      $('#profile-unauthorized a').hide();
      $('#profile-authorized-smp a').show();
      $('#profile-unauthorized-smp a').hide();
      $('#header-menu-authorized').show();
      $('#header-menu-unauthorized').hide();
    })
    .catch(() => {
      $('.profile-authorized a').hide();
      $('#profile-unauthorized a').show();
      $('#profile-authorized-smp a').hide();
      $('#profile-unauthorized-smp a').show();
      $('#header-menu-authorized').hide();
      $('#header-menu-unauthorized').show();
    })
    .always(() => {
      const showJqXHR = getShowJqXHR();
      // const tagJqXHR = getTagJqXHR();
      const categoryJqXHR = getCategoryJqXHR();
      const categoryProductJqXHR = getCategoryProductJqXHR();
      const productTagJqXHR = getProductTagJqXHR();
      const provinceJqXHR = getProvinceJqXHR();
      const areaProvinceJqXHR = getAreaProvinceJqXHR();
      const meetingJqXHR = getMgJqXHR();
      const topsectionJqXHR = getTopSectionJqXHR();
      const welconmeToGiftNetJqXHR = postWelconmeToGiftNetJqXHR();
      $.when(
        showJqXHR,
        categoryJqXHR,
        categoryProductJqXHR,
        productTagJqXHR,
        provinceJqXHR,
        areaProvinceJqXHR,
        meetingJqXHR,
        topsectionJqXHR
      )
        .done(
          (
            jqShow,
            jqCategory,
            jqCategoryProduct,
            jqProductTag,
            jqProvince,
            areaJqProvince,
            jqMeeting,
            jqTopSection
          ) => {
            const shows: GiftShow[] = jqShow[0];
            const categories: Category[] = jqCategory[0];
            const categoriesProduct: CategoryProduct[] = jqCategoryProduct[0];
            // const hashtags: string[] = jqTag[0];
            const hashtags: ProductHashTag[] = jqProductTag[0];
            const prefectures: Province[] = jqProvince[0];
            const areaPrefectures: AreaProvince[] = areaJqProvince[0];
            const meetings: Meeting[] = jqMeeting[0];
            const topsections: TopSection[] = jqTopSection[0].data;

            // 過去に開催された見本市を追加
            let pastExhibitionList: string = '';

            shows.forEach((item) => {
              pastExhibitionList += GiftShowComponent({
                className: item.name_tag,
                start_date: item.start_date,
                end_date: item.end_date,
                show_code: item.show_code,
                show_img: item.show_img,
                show_datename: item.show_datename,
                show_address: item.show_address,
              });
            });
            $('#exhibition-list-more').html(pastExhibitionList);
            // slick
            checkBreakPointGiftshow();
            // 検索フォームのプレースホルダー切り替え
            const $topSectionSelect = $('#exampleFormControlSelect1');
            const $topSectionForm = $('#freeword-input');
            $topSectionForm.attr(
              'placeholder',
              $topSectionSelect.val() as string
            );

            // default trn key
            let searchType: TopSectionSearchType = 'searchFreeword';

            $topSectionSelect.on('change', () => {
              searchType = $('option:selected').data(
                'trnKey'
              ) as TopSectionSearchType;

              const value = $topSectionSelect.val() as string;
              $topSectionForm.attr('placeholder', value);
            });

            // トップセクションから検索
            const topSectionSearch = () => {
              const value = $topSectionForm.val() as string;
              if (searchType && value) {
                let urlParam: string;

                switch (searchType) {
                  case 'searchFreeword':
                    urlParam = createParams({
                      freeword: encodeURIComponent(value),
                    });
                    window.location.href = `/result/${urlParam}`;
                    return;
                  case 'searchCompanyName':
                    urlParam = createParams({
                      registered_name: encodeURIComponent(value),
                    });
                    window.location.href = `/result/${urlParam}`;
                    return;
                  case 'searchProductName':
                    urlParam = createParams({
                      product_name: encodeURIComponent(value),
                    });
                    window.location.href = `/product-result/${urlParam}`;
                    return;
                  case 'searchHashTagName':
                    urlParam = createParams({
                      hashtag: encodeURIComponent(value),
                    });
                    window.location.href = `/product-result/${urlParam}`;
                    return;
                }
              } else {
                setTimeout(() => {
                  Alert({
                    icon: 'warning',
                    trnKey: 'errFreeword',
                  });
                }, 500);
              }
            };

            $('#freeword-search-btn').on('click', () => {
              topSectionSearch();
            });
            $topSectionForm.on('keypress', (e) => {
              if (e.which === 13) topSectionSearch();
            });

            // トップセクションインジケーター
            let topsectionIndicatorList: string = '';
            topsections.forEach((item, index) => {
              topsectionIndicatorList += `
                   <li
                    data-slide-to="${index}"
                    data-target="#carouselExampleIndicators"
                  ></li>
              `;
            });
            $('#carousel-indicators').html(topsectionIndicatorList);
            // トップセクション画像URL
            let topsectionList1: string = '';
            let topsectionList2: string = '';
            topsections.forEach((item, index) => {
              let active: string = '';
              if (item.publish === 'yes') {
                if (index == 0) {
                  active = 'active';
                }
                topsectionList1 += TopSectionComponent({
                  className: 'topsection-label',
                  active: active,
                  alt: item.eycatch_name,
                  src: item.eycatch_img,
                  url: item.eycatch_url,
                });
                topsectionList2 += TopSectionComponent({
                  className: 'topsection-label',
                  active: active,
                  alt: item.eycatch_name,
                  src: item.eycatch_img,
                  url: item.eycatch_url,
                });
              }
            });

            $('#carousel-inner1').html(topsectionList1);
            $('#carousel-inner2').html(topsectionList2);
            // 産地商談会
            let meetingList: string = '';
            meetings.forEach((item) => {
              meetingList += MeetingComponent({
                className: 'meeting-label',
                name: item.name,
                url: item.url,
                show_img: item.show_img,
                name_tag: item.name_tag,
              });
            });
            $('#meeting-list').html(meetingList);
            checkSlickMeeting();
            // カテゴリ (取り扱い品目を探す)
            let categoryList: string = '';
            categories.forEach((item) => {
              categoryList += CategoryComponent({
                className: 'category-label',
                value: item.category_code,
                category_code: item.category_code,
                category_name: item.category_name,
                img_url: item.img_url,
              });
            });
            $('#category-list').html(categoryList);
            $(document).on('click', '.category-label', function () {
              const active: boolean = $(this).hasClass('active');
              if (active) {
                $(this).removeClass('active');
              } else {
                $(this).addClass('active');
              }
            });
            $('#category-search-btn').on('click', () => {
              let category = '';
              $('#category-list .category-label.active').each((i, el) => {
                if (i !== 0) category += ',';
                category += `${$(el).data('value')}`;
              });
              if (category) {
                const urlParam = createParams({ category: category });
                window.location.href = `./result/${urlParam}`;
              } else {
                Alert({
                  icon: 'warning',
                  trnKey: 'errCategory',
                });
              }
            });

            // 商品カテゴリ 親要素と各子要素取得 (グッズから探す)
            let categoryProductList: string = '';
            categoriesProduct.forEach((item) => {
              categoryProductList += CategoryProductComponent({
                className: 'category-product__label',
                category_index: item.mainItemCategoryValueIndex,
                category_name: item.mainItemCategoryValue,
                img_url: item.imgUrl,
                child_items: item.mainItems,
              });
            });
            $('#category-product-list').html(categoryProductList);
            $('.category-product-link').on('click', function () {
              let categoryMoreList: string = $(this).data('value');
              if (categoryMoreList) {
                const urlParam = createParams({
                  category: categoryMoreList,
                });
                window.location.href = `./product-result/${urlParam}`;
              } else {
                Alert({
                  icon: 'warning',
                  trnKey: 'errHashtag',
                });
              }
            });

            // ハッシュタグ
            const hashtagCount = 28; // 表示件数
            let hashtagList: string = '';
            hashtags.forEach((item, index) => {
              if (index < hashtagCount)
                hashtagList += HashtagLabelComponent({
                  className: 'hashtag-label',
                  value: item.tag_name,
                  name: `#${item.tag_name}`,
                });
            });
            $('#hashtag-list').html(hashtagList);

            // 全てのハッシュタグ
            let hashtagListAll: string = '';
            hashtags.forEach((item, index) => {
              hashtagListAll += HashtagLabelComponent({
                className: 'hashtag-label',
                value: item.tag_name,
                name: `#${item.tag_name}`,
              });
            });
            $('#hashtag-list-all').html(hashtagListAll);
            $('#hashtag-more-link').on('click', function () {
              let hashtagMoreList: string = '';
              hashtags.forEach((item, index) => {
                if (index >= hashtagCount)
                  hashtagMoreList += HashtagLabelComponent({
                    className: 'hashtag-label',
                    value: item.tag_name,
                    name: `#${item.tag_name}`,
                  });
              });
              $('#hashtag-list').append(hashtagMoreList);
              $(this).hide(); // リンクを非表示にする
            });

            // ハッシュタグ検索
            $('#hashtag-list .hashtag-label').on('click', function () {
              // let hashtag: string = '';
              let hashtag: string = $(this).data('value');

              if (hashtag) {
                const urlParam = createParams({
                  hashtag: encodeURIComponent(hashtag),
                });
                window.location.href = `./product-result/${urlParam}`;
              } else {
                Alert({
                  icon: 'warning',
                  trnKey: 'errHashtag',
                });
              }
            });

            // ハッシュタグ検索
            $('#hashtag-list-all .hashtag-label').on('click', function () {
              // let hashtag: string = '';
              let hashtag: string = $(this).data('value');

              if (hashtag) {
                const urlParam = createParams({
                  hashtag: encodeURIComponent(hashtag),
                });
                window.location.href = `./product-result/${urlParam}`;
              } else {
                Alert({
                  icon: 'warning',
                  trnKey: 'errHashtag',
                });
              }
            });

            // アイウエオ・ABC (スマホ)
            let abcList: string =
              '<option class="trn" data-trn-key="unselect" value="" selected>未選択</option>';
            if (lang === 'ja') {
              nameIndexs.forEach((item) => {
                abcList += `<option value='${item}'>${item}</option>`;
              });
            } else {
              nameIndexs_en.forEach((item) => {
                abcList += `<option value='${item}'>${item}</option>`;
              });
            }

            $('#abc-list').html(abcList);
            $('#abc-search-btn').on('click', () => {
              const abc = $('#abc-list').val() as string;
              if (abc !== '') {
                const urlParam = createParams({ abc: encodeURIComponent(abc) });
                window.location.href = `./result/${urlParam}`;
              } else {
                Alert({
                  icon: 'warning',
                  trnKey: 'errAbc',
                });
              }
            });

            let abcNameIndex = [];
            if (lang === 'ja') {
              abcNameIndex = abcNameWebIndex;
              // アイウエオ ま〜 (Web)
              let abcListRight: string = '';
              defNameWebIndex.forEach((item, index) => {
                abcListRight += AbcComponent({
                  className: 'abc-label',
                  index: index,
                  main_name: item.main_name,
                  detail: item.detail,
                });
              });

              $('#abc-list-right').html(abcListRight);
            } else {
              abcNameIndex = abcNameWebIndex_en;
            }

            // アイウエオ あ〜 (Web)
            let abcListLeft: string = '';
            abcNameIndex.forEach((item, index) => {
              abcListLeft += AbcComponent({
                className: 'abc-label',
                index: index,
                main_name: item.main_name,
                detail: item.detail,
              });
            });
            $('#abc-list-left').html(abcListLeft);

            $('.abc-list-value-li').on('click', function () {
              const abc = $(this).text() as string;
              if (abc !== '') {
                const urlParam = createParams({ abc: encodeURIComponent(abc) });
                window.location.href = `./result/${urlParam}`;
              } else {
                Alert({
                  icon: 'warning',
                  trnKey: 'errAbc',
                });
              }
            });

            // 都道府県
            let prefectureList: string =
              '<option class="trn" data-trn-key="unselect" value="0" selected>未選択</option>';
            prefectures.forEach((item) => {
              prefectureList += `<option value='${item.province_code}'>${item.province_name}</option>`;
            });
            $('#prefecture-list').html(prefectureList);
            $('#prefecture-search-btn').on('click', () => {
              const prefecture = $('#prefecture-list').val() as string;
              if (prefecture !== '0') {
                const urlParam = createParams({
                  prefecture: prefecture,
                });
                window.location.href = `./result/${urlParam}`;
              } else {
                Alert({
                  icon: 'warning',
                  trnKey: 'errPrefecture',
                });
              }
            });

            // 都道府県 (Web版)
            let areaPrefectureList: string = '';
            areaPrefectures.forEach((item) => {
              areaPrefectureList += AreaPrefectureComponent({
                className: 'prefecture-label',
                area_name: item.area_name,
                provinces: item.provinces,
              });
            });
            if (lang === 'en') {
              areaPrefectureList +=
                `
                  <div class='prefecture-row'>
                  <h4 class='sub-text prefecture-title'>Others</h4>
                    <ul>
                      <a class='card-link' href='./result/?prefecture=48'>
                        <li>Overseas</li>
                      </a>
                    </ul>
                  </div>
                `;
            } else {
              areaPrefectureList +=
                `
                  <div class='prefecture-row'>
                  <h4 class='sub-text prefecture-title'>その他</h4>
                    <ul>
                      <a class='card-link' href='./result/?prefecture=48'>
                        <li>海外</li>
                      </a>
                    </ul>
                  </div>
                `;
            }

            $('#areaprefecture-list').html(areaPrefectureList);
            $('#areaprefecture-search-btn').on('click', () => {
              const prefecture = $('#prefecture-list').val() as string;
              if (prefecture !== '0') {
                const urlParam = createParams({
                  prefecture: prefecture,
                });
                window.location.href = `./result/${urlParam}`;
              } else {
                Alert({
                  icon: 'warning',
                  trnKey: 'errPrefecture',
                });
              }
            });
          }
        )
        .fail(() => {
          Alert({
            icon: 'error',
            trnKey: 'errGetData',
            confirmButtonKey: 'errReload',
            onClick: () => location.reload(),
          });
        })
        .always(() => {
          translate(); // 翻訳
          $('#loading').hide(); // ロードを解除
        });
    });
});
