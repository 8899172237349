import $ from 'jquery';
import { getCookie } from './util/cookie';
import { SearchCondition } from './model/index';

const apiurl = 'https://www.giftnet.jp/api/gift-net';

// Token 取得
const token = getCookie('loginToken');

// 認証ユーザー情報 取得
export const getUserJqXHR = () => {
  return $.ajax(`${apiurl}/customer/users/current`, {
    type: 'GET',
    headers: {
      Accept: 'application/json',
      Authorization: `${token}`,
    },
  });
};

// ログアウト
export const logoutJqXHR = () => {
  return $.ajax(`${apiurl}/gift/auth/logout`, {
    type: 'POST',
    headers: {
      Accept: 'application/json',
      Authorization: `${token}`,
    },
  });
};

// 広告 取得
export const getAdJqXHR = () => {
  const lang = localStorage.getItem('lang') ?? 'ja';
  return $.ajax(`${apiurl}/search/get/ad`, {
    type: 'GET',
    data: {
      lang: lang,
    },
    headers: {
      Accept: 'application/json',
    },
  });
};

// 広告をクリックする時叩く
export const postAdJqXHR = (id: number) => {
  return $.ajax(`${apiurl}/search/click/ad/${id}`, {
    type: 'POST',
    headers: {
      Accept: 'application/json',
    },
  });
};

// スペシャルコンテンツ 取得
export const getScJqXHR = () => {
  const lang = localStorage.getItem('lang') ?? 'ja';
  return $.ajax(`${apiurl}/search/get/sc`, {
    type: 'GET',
    data: {
      lang: lang,
    },
    headers: {
      Accept: 'application/json',
    },
  });
};

// スペシャルコンテンツをクリックする時叩く
export const postScJqXHR = (id: number) => {
  return $.ajax(`${apiurl}/search/click/sc/${id}`, {
    type: 'POST',
    headers: {
      Accept: 'application/json',
    },
  });
};

// ビジネスガイド社からのお知らせ　取得 (旧特集)
export const getSfJqXHR = () => {
  const lang = localStorage.getItem('lang') ?? 'ja';
  return $.ajax(`${apiurl}/search/get/sf`, {
    type: 'GET',
    data: {
      lang: lang,
    },
    headers: {
      Accept: 'application/json',
    },
  });
};

// ビジネスガイド社からのお知らせをクリックする時叩く (旧特集)
export const postSfJqXHR = (id: number) => {
  return $.ajax(`${apiurl}/search/click/sf/${id}`, {
    type: 'POST',
    headers: {
      Accept: 'application/json',
    },
  });
};

// 産地商談会取得
export const getMgJqXHR = () => {
  const lang = localStorage.getItem('lang') ?? 'ja';
  return $.ajax(`${apiurl}/search/seto/localShow?lang=ja`, {
    type: 'GET',
    data: {
      lang: lang,
    },
    headers: {
      Accept: 'application/json',
    },
  });
};

// 検索結果⼀覧取得
export const searchJqXHR = (page: number, cond: SearchCondition) => {
  const lang = localStorage.getItem('lang') ?? 'ja';
  return $.ajax(`${apiurl}/search/shopList?lang=${lang}`, {
    method: 'POST',
    contentType: 'application/json; charset=UTF-8',
    data: JSON.stringify({
      page: `${page}`,
      page_size: '20',
      show_code: cond.show_code,
      floor_code: cond.floor_code,
      fair_code: cond.fair_code,
      category_code: cond.category_code,
      tag: cond.tag,
      name_index: cond.name_index,
      province_code: cond.province_code,
      keywords: cond.freeword !== '' ? [cond.freeword] : [],
      sc_key: cond.sc_key,
      registered_name: cond.registered_name,
      order: cond.order,
    }),
    headers: {
      Accept: 'application/json',
      Authorization: `${token}`,
    },
  });
};

// 商品検索結果⼀覧取得
export const productSearchJqXHR = (page: number, cond: SearchCondition) => {
  const lang = localStorage.getItem('lang') ?? 'ja';
  return $.ajax(`${apiurl}/search/productList?lang=${lang}`, {
    method: 'POST',
    contentType: 'application/json; charset=UTF-8',
    data: JSON.stringify({
      page: `${page}`,
      page_size: '100',
      show_code: cond.show_code,
      floor_code: cond.floor_code,
      fair_code: cond.fair_code,
      category_code: cond.category_code,
      tag: cond.tag,
      name_index: cond.name_index,
      province_code: cond.province_code,
      keywords: cond.freeword !== '' ? [cond.freeword] : [],
      sc_key: cond.sc_key,
      product_name: cond.product_name,
    }),
    headers: {
      Accept: 'application/json',
      Authorization: `${token}`,
    },
  });
};

// PR⼀覧取得
export const prSearchJqXHR = () => {
  const lang = localStorage.getItem('lang') ?? 'ja';
  return $.ajax(`${apiurl}/search/prList?lang=${lang}`, {
    type: 'GET',
    headers: {
      Accept: 'application/json',
    },
  });
};

// お気に入り一覧 取得
export const getFavoriteJqXHR = () => {
  const lang = localStorage.getItem('lang') ?? 'ja';
  return $.ajax(`${apiurl}/search/favorite?lang=${lang}`, {
    type: 'GET',
    headers: {
      Accept: 'application/json',
      Authorization: `${token}`,
    },
  });
};

// お気に入り登録
export const postFavoriteJqXHR = (shop_id: string) => {
  return $.ajax(`${apiurl}/search/favorite/${shop_id}`, {
    type: 'POST',
    headers: {
      Accept: 'application/json',
      Authorization: `${token}`,
    },
  });
};

// お気に入り削除
export const deleteFavoriteJqXHR = (shop_id: string) => {
  return $.ajax(`${apiurl}/search/favorite/${shop_id}`, {
    type: 'DELETE',
    headers: {
      Accept: 'application/json',
      Authorization: `${token}`,
    },
  });
};

// いいねに追加
export const postLikeJqXHR = (shop_id: string, product_id: string) => {
  return $.ajax(
    `${apiurl}/customer/shops/${shop_id}/products/${product_id}/favorites`,
    {
      type: 'POST',
      headers: {
        Accept: 'application/json',
        Authorization: `${token}`,
      },
    }
  );
};

// いいねから削除
export const deleteLikeJqXHR = (shop_id: string, product_id: string) => {
  return $.ajax(
    `${apiurl}/customer/shops/${shop_id}/products/${product_id}/favorites`,
    {
      type: 'DELETE',
      headers: {
        Accept: 'application/json',
        Authorization: `${token}`,
      },
    }
  );
};

// 展示会の表示
export const getShowJqXHR = () => {
  const lang = localStorage.getItem('lang') ?? 'ja';
  return $.ajax(`${apiurl}/search/shows`, {
    type: 'GET',
    data: {
      lang: lang,
    },
    headers: {
      Accept: 'application/json',
    },
  });
};

// 展示会のフロアコードとフロア名の一覧を取得
export const getFloorJqXHR = (show_code: string) => {
  const lang = localStorage.getItem('lang') ?? 'ja';
  return $.ajax(`${apiurl}/search/floors`, {
    type: 'GET',
    data: {
      lang: lang,
      show_code: show_code,
    },
    headers: {
      Accept: 'application/json',
    },
  });
};

// 展示会の出展コードと出展名の一覧を取得
export const getFairJqXHR = (show_code: string) => {
  const lang = localStorage.getItem('lang') ?? 'ja';
  return $.ajax(`${apiurl}/search/fairs`, {
    type: 'GET',
    data: {
      lang: lang,
      show_code: show_code,
    },
    headers: {
      Accept: 'application/json',
    },
  });
};

// 商品カテゴリ一覧取得 (取り扱い品目を探す) 出展社検索ページへ
export const getCategoryJqXHR = () => {
  const lang = localStorage.getItem('lang') ?? 'ja';
  return $.ajax(`${apiurl}/search/categories`, {
    type: 'GET',
    data: {
      lang: lang,
    },
    headers: {
      Accept: 'application/json',
    },
  });
};

// 商品カテゴリマスタ取得 (グッズから探す) 商品検索ページへ
export const getCategoryProductJqXHR = () => {
  const lang = localStorage.getItem('lang') ?? 'ja';
  return $.ajax(`${apiurl}/common/master/main_item_categories`, {
    type: 'GET',
    data: {
      lang: lang,
    },
    headers: {
      Accept: 'application/json',
    },
  });
};

// ハッシュタグ一覧取得
export const getTagJqXHR = () => {
  const lang = localStorage.getItem('lang') ?? 'ja';
  return $.ajax(`${apiurl}/search/tags`, {
    type: 'GET',
    data: {
      lang: lang,
    },
    headers: {
      Accept: 'application/json',
    },
  });
};

// 人気のハッシュタグから探す (商品ハッシュタグ一覧取得)
export const getProductTagJqXHR = () => {
  const lang = localStorage.getItem('lang') ?? 'ja';
  return $.ajax(`${apiurl}/search/product/tags?`, {
    type: 'GET',
    data: {
      lang: lang,
    },
    headers: {
      Accept: 'application/json',
    },
  });
};

// 都道府県コードと都道府県名の一覧を取得 (スマホ用)
export const getProvinceJqXHR = () => {
  const lang = localStorage.getItem('lang') ?? 'ja';
  return $.ajax(`${apiurl}/search/provinces`, {
    type: 'GET',
    data: {
      lang: lang,
    },
    headers: {
      Accept: 'application/json',
    },
  });
};
// 都道府県コードと都道府県名の一覧を取得 (Web用)
export const getAreaProvinceJqXHR = () => {
  const lang = localStorage.getItem('lang') ?? 'ja';
  return $.ajax(`${apiurl}/search/areaProvinces`, {
    type: 'GET',
    data: {
      lang: lang,
    },
    headers: {
      Accept: 'application/json',
    },
  });
};

// 検討リストに追加
export const postConsiderJqXHR = (shop_id: string) => {
  return $.ajax(`${apiurl}/customer/shops/${shop_id}/considerations`, {
    type: 'POST',
    headers: {
      Accept: 'application/json',
      Authorization: `${token}`,
    },
  });
};

// 検討リストから削除
export const deleteConsiderJqXHR = (shop_id: string) => {
  return $.ajax(`${apiurl}/customer/shops/${shop_id}/considerations`, {
    type: 'DELETE',
    headers: {
      Accept: 'application/json',
      Authorization: `${token}`,
    },
  });
};

// トップセクションの画像とurlを取得
export const getTopSectionJqXHR = () => {
  const lang = localStorage.getItem('lang') ?? 'ja';
  return $.ajax(`${apiurl}/common/master/top_section?`, {
    type: 'GET',
    data: {
      lang: lang,
    },
    headers: {
      Accept: 'application/json',
    },
  });
};

// giftnet開く
export const postWelconmeToGiftNetJqXHR = () => {
  return $.ajax(`${apiurl}/customer/users/welcome`, {
    type: 'POST',
    headers: {
      Accept: 'application/json',
    },
  });
};
