import { MainItem } from '../model/index';

interface IProps {
  className: string;
  active?: boolean;
  category_index: number;
  category_name: string;
  img_url: string;
  child_items: MainItem[];
}
interface ChildProps {
  mainItemValueIndex: number;
  mainItemValue: string;
}
const CategoryProductComponent = (props: IProps): string => {
  let child_valueList: string = '';

  const categoryChildContent = props.child_items.map((item: ChildProps) => {
    child_valueList += `
<div>
     <a id='mainItemValue_${item.mainItemValue}' class='category-product-link card card-link pointer d-block m-1'  data-value='${item.mainItemValueIndex}'>
           <div class='card-body label-body p-2'>
               <span class='label-text d-inline-block'>${item.mainItemValue}</span>
           </div>
     </a>
</div>
   `;
  });
  return `
         <div class='card pointer col-12 col-md-6 col-lg-3 ml-0 ${props.className}' data-value='${props.category_index}'>
            <div
              class='card-body label-body card-link p-0 d-flex align-items-center'
              data-toggle='modal'
              data-target='#category_product_${props.category_index}'
            >
              <img
                class='label-img d-block mx-3''
                src='${props.img_url}'
                alt='${props.category_name}'
              />
              <span class='label-text d-block pt-2'>${props.category_name}</span>
              <span class= "ml-auto mr-3 text-muted">
                  <i class="fa fa-angle-right"></i>             
                </span>
            </div>
          </div>
          <div
            class='modal fade'
            id='category_product_${props.category_index}'
            tabindex='-1'
            role='dialog'
            aria-labelledby='exampleModalCenterTitle'
            aria-hidden='true'
          >
            <div
              class='modal-dialog modal-xl modal-dialog-centered'
              role='document'
            >
              <div class='modal-content container-fluid'>
                <div class='modal-header'>
                  <h5 class='modal-title' id='exampleModalCenterTitle'>
                    ${props.category_name}
                  </h5>
                </div>
                <div class='modal-body row'>
                ${child_valueList}
                </div>
              </div>
            </div>
          </div>     `;
};
export default CategoryProductComponent;
