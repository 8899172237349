interface IProps {
  // value: number | string;
  // name: string;
  className: string;
  start_date: string;
  end_date: string;
  show_code: string;
  show_img: string;
  show_datename: string;
  show_address: string;
}

const HashtagLabelComponent = (props: IProps): string => {
  const todayDate = new Date().getTime();
  const startDate = new Date(props.start_date).getTime();
  const endDate = new Date(props.end_date).getTime();

  let holdFlag: string = '';
  let notHoldFlag: string = '';

  // 開催中かの判定
  if (startDate <= todayDate && endDate >= todayDate) {
    holdFlag +=
      "<span class='exhibition-tag d-inline-block trn' data-trn-key='open'>開催中</span>";
  } else {
    notHoldFlag +=
      "<span class='exhibition-tag d-inline-block ' style='height: 28px; background: none'></span>";
  }
  return `
            <div class='card card-link card-shadow exhibition-card'>
             <a class='card-link d-block'  href='/result/?show=${
               props.show_code
             }'>
                    <div class='card-body' style="padding: .7rem; height: 355px">
                         ${holdFlag} 
                         ${notHoldFlag}
                         <div class='card-img__body d-flex align-items-center' style='height: 120px'>
                      <img
                        alt='Card image cap'
                        class='card-img exhibition-img d-block'
                        src='${props.show_img}'
                        style="width: 80%; margin: auto;"
                      />   
              </div>
                      <span class='exhibition-tag d-inline-block trn'
                      data-trn-key='exhibitionDate'>会期</span
                      >
                      <p class='exhibition-date card-text'>
                      ${props.show_datename ?? ''}
                      </p>
                      <span class='exhibition-tag d-inline-block trn'
                      data-trn-key='exhibitionPlace'>会場</span
                      >
                      <p class='exhibition-place card-text'>
                      ${props.show_address ?? ''}
                      </p>
                    </div>
                </a>
                </div>
  `;
};
export default HashtagLabelComponent;
