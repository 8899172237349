export const getCookie = (name: string) => {
  let result = null;

  const cookieName = name + '=';
  const allcookies = document.cookie;

  const position = allcookies.indexOf(cookieName);
  if (position != -1) {
    const startIndex = position + cookieName.length;

    let endIndex = allcookies.indexOf(';', startIndex);
    if (endIndex == -1) {
      endIndex = allcookies.length;
    }

    result = decodeURIComponent(allcookies.substring(startIndex, endIndex));
  }

  return result;
};

export const setCookie = (name: string, value: string) => {
  document.cookie = `${name}=${encodeURIComponent(value)}; path=/;`;
};

export const removeCookie = (name: string) => {
  const date = new Date();
  date.setTime(date.getTime() - 1);
  document.cookie = `${name}=; expires=${date.toUTCString()}; path=/;`;
};
